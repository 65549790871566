import React from 'react'
import {Link} from 'react-router-dom';
import '../CSS/HiddenNavbar.css'
import logo from '../assets/logo.jpeg'


export default function HiddenNavbar({showFixed}) {

  const categories = ["Bhilai-Durg", "CG", "BSP", "Politics", "Crime", "National", "International", "Sports", "Education", "Employment", "Bollywood", "Tourism", "Astrology"];
  const generateCategoryLink = (category) => `/cat?category=${category}`;
  const authToken = localStorage.getItem('newsapp_auth_token');
  const showAdminLinks = authToken !== null;

  return (
    <div id='navbar' className={showFixed?"fixed":""} style={showFixed?{ backgroundColor: "black", display:"flex"}:{display:"none"}}>
      <div id='navbar_content_box' className="container-fluid d-flex" style={{ width: "75%", height: "10vh", marginLeft:"4%" }}>
        <div className="d-flex" id='nav_head' style={{ alignItems: "center", paddingTop: "1px" }}>
        <Link to="/"><img style={{width:"100px", height:"9vh"}} src={logo} alt="Aap tak Chhattisgarh logo" /></Link>
        </div>
        <div id='nav_contents' className="d-flex" style={{ alignItems: "center", paddingTop: "18px", marginLeft: "4%", whiteSpace:"nowrap" }}>
          <Link className='category' to="/" style={{ marginLeft: '3%', color: "#ffff", fontFamily:"", fontSize: "1vw", fontWeight:"bold", textDecoration: "none" }}>Main</Link>
          {categories.map((category, index) => (
              <Link
                key={index}
                to={generateCategoryLink(category)}
                className='category'
                style={{ marginLeft: '3%', color: "#ffff", fontSize: "1vw", fontWeight:"bold", textDecoration: "none" }}
                activeClassName="active-link"
              >
                {category}
              </Link>
            ))}
            {!showAdminLinks ? null
              :
              <>
                <Link className='category' to="/addNews" style={{ marginLeft: '2.5%', fontFamily:"", color: "white", fontSize: "0.7vw", fontWeight:"bold", textDecoration: "none" }} activeClass="active-link">News</Link>
                <Link className='category' to="/addAd" style={{ marginLeft: '1.5%', fontFamily:"", color: "white", fontSize: "0.7vw", fontWeight:"bold", textDecoration: "none" }} activeClass="active-link">Ad</Link>
                <Link className='category' to="/" style={{ marginLeft: '1.5%', fontFamily:"", color: "white", fontSize: "0.7vw", fontWeight: "bold", textDecoration: "none" }} onClick={()=>localStorage.removeItem("newsapp_auth_token")} activeClass="active-link">logout</Link>
              </>
            }
        </div>
      </div>
    </div>
  )
}
