import React from 'react'
import '../CSS/Loader.css';

export default function LoadingPage() {
  return (
    <div id='loading_div' className="d-flex justify-content-center align-items-center">
        <div className=' loader'>
      
      </div>
    </div>
  )
}
