import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, Link } from 'react-router-dom';
import '../CSS/Login.css'
import anotherLogo from '../assets/logo-removebg-preview.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const navigation = useNavigate();
  const handleLogin = async () => {
    // Basic username validation
    // Basic password validation (at least 6 characters)
    const isValidPassword = password.length >= 4;
    const isValidUsername = username.length >= 4;

    if (isValidUsername && isValidPassword) {
      try {
        const response = await fetch('https://d19rkiagm94rwl.cloudfront.net/api/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userName: username,
            password: password,
          }),
        });

        // console.log(response);
        // response.json().then((result)=>{
        // //  console.log(result)
        // })

        if (response.status === 200) {
          // Successful login
          const data = await response.json();
          const authToken = data.token; // Assuming the server returns a token

          // Store the token in local storage
          localStorage.setItem('newsapp_auth_token', authToken);
        //  console.log(authToken)
          // Clear the form fields
          setUsername('');
          setPassword('');

          navigation("/")


          // Handle any additional logic you need for a successful login
        } else {
          // Handle login failure (e.g., incorrect credentials)
          setShowError(true);
          setError("Invalid Credentials");
          console.error('Login failed');
        }
      } catch (error) {
        // Handle other errors (e.g., network issues)
        setShowError(true);
        setError("Error occurred during login")
        console.error('Error during login:', error);
      }
    } else {
      // Show error message
      setShowError(true);
      setError("Please enter a valid username and password.");
    }
  };


  return (
    <div className="d-flex login_page_div align-items-center justify-content-center" style={{ height: '100vh', backgroundColor: '#f8f9fa' }}>
      <Container>
        <div className="d-flex" id='page_heading' style={{ justifyContent: "center", alignItems: "center", marginTop: "-17vh" }}>
          <Link to="/"><img style={{ width: "350px", height: "200px", marginTop: "10px" }} src={anotherLogo} alt="Aap tak Chhattisgarh logo" /></Link>
        </div>
        <Row className="justify-content-center" >
          <Col md={6} className="p-4 shadow bg-white" style={{borderRadius:"30px"}}>
            <div className="d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
              <i className="fa-solid fa-user-secret fa-4x"></i>
            </div>
            <h2 className="mb-4 text-center">Login</h2>

            {/* Show error message if validation fails */}
            {showError && (
              <Alert variant="danger" className="mb-3">
                {error}
              </Alert>
            )}

            <Form>
              <Form.Group controlId="formBasicusername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <div className="d-flex" style={{ justifyContent: "center" }}>
                <Button variant="danger" className='mt-4 btn-lg' type="button" block onClick={() => navigation("/")}>
                  Home
                </Button>
                <Button variant="primary" className='mt-4 btn-lg ml-4' type="button" block onClick={handleLogin}>
                  Login
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
