import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import '../CSS/Header.css'
import anotherLogo from '../assets/logo-removebg-preview.png';

export default function Header() {

  const [currentHeadlineIndex, setCurrentHeadlineIndex] = useState(0);
  const [headline, setHeadline] = useState([]);
  const [sublogo, setSublogo] = useState();

  useEffect(() => {
    fetchCategoryWiseNews();
    fetchAdA1()
    fetchSubLogo();
  }, []);

  const fetchSubLogo = () => {
    fetch(`https://d19rkiagm94rwl.cloudfront.net/api/ads/user/logo`)
      .then(response => {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        return response.json();
      })
      .then(data => {
        setSublogo(data.adsUrl);
      })
      .catch(error => console.error('Error:', error));
  }

  // const fetchCategoryWiseNews = () => {
  //   fetch(`https://d19rkiagm94rwl.cloudfront.net/api/photos/news/latest`)
  //     .then(response => {
  //       if (!response.ok)
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       return response.json();
  //     })
  //     .then(data => {
  //       setHeadings(data.map(item => item.heading));
  //       let head = "";
  //       for (let i = 0; i < 5; i++) {
  //         head = head + data[i].heading + " | ";
  //       }
  //       setHeadline(head);
  //     })
  //     .catch(error => console.error('Error:', error));
  // }

  const fetchCategoryWiseNews = async () => {
    try {
      const response = await fetch("https://d19rkiagm94rwl.cloudfront.net/api/photos/news/latest");
      if (!response.ok) throw new Error(`HTTP error Status: ${response.status}`);
      const data = await response.json();
      setHeadline(data.map(item => item.heading));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (headline.length > 0) {
      const interval = setInterval(() => {
        setCurrentHeadlineIndex((prevIndex) => (prevIndex + 1) % Math.min(5, headline.length)); // Show only up to 5 headlines
      }, 10000); // 5 seconds for each headline

      return () => clearInterval(interval); // Clear interval on unmount
    }
  }, [headline]);


  const categories = ["Bhilai-Durg", "CG", "BSP", "Politics", "Crime", "National", "International", "Sports", "Education", "Employment", "Bollywood", "Tourism", "Astrology"];
  const generateCategoryLink = (category) => `/cat?category=${category}`;
  const [adA1, setAdA1] = useState("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOeKBjU0XSXCMTl682OTUC4qrkAlpLcV2x9g&usqp=CAU");

  const fetchAdA1 = () => {
    fetch("https://d19rkiagm94rwl.cloudfront.net/api/ads/user/adbyid/1").then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
      .then(data => {
        // Assuming the server responds with a JSON object containing the image link
        const imageLink = data.adsUrl;
        if (imageLink) setAdA1(imageLink);
        // console.log('Image Link:', imageLink);
      })
      .catch(error => console.error('Error:', error));
  }



  const authToken = localStorage.getItem('newsapp_auth_token');
  const showAdminLinks = authToken !== null;

  const [toggleNavbar, setToggleNavbar] = useState(false);


  const handleToggleNavbar = () => {
    setToggleNavbar(!toggleNavbar);
  }


  return (
    <div>
      <div id='navbar_content_box_tab' className="container-fluid d-flex bg-light fixed-top" style={{ width: "100%", height: "auto" }}>
        <div className="d-flex" id='nav_head_tab' style={{}}>
          <Link to="/"><img style={{ width: "120px", height: "120px", marginTop: "-9px" }} src={anotherLogo} alt="Aap tak Chhattisgarh logo" /></Link>
        </div>
        <div className="ham-icon mt-4" style={{ marginLeft: "80%", cursor: "pointer" }}>
          <i onClick={handleToggleNavbar} className="fa-solid fa-bars fa-2x"></i>
        </div>
      </div>
      {toggleNavbar && <div id='nav_contents_tab' className='fixed-top'>
        <Link className='category_tab' to="/" style={{ textDecoration: "none", color: "black", fontWeight: "bold", paddingTop: "10px", fontSize: "2.3vw" }}>Main</Link>
        {categories.map((category, index) => (
          <Link
            key={index}
            to={generateCategoryLink(category)}
            className='category'
            activeClassName="active-link"
            style={{ textDecoration: "none", color: "black", fontWeight: "bold", paddingTop: "10px", fontSize: "2.3vw" }}
          >
            {category}
          </Link>
        ))}
        {!showAdminLinks ? null
          :
          <>
            <Link className='category_tab' to="/addNews" style={{ textDecoration: "none", color: "black", fontWeight: "bold", paddingTop: "5px", fontSize: "2.3vw" }} activeClass="active-link">Add News</Link>
            <Link className='category_tab' to="/addAd" style={{ textDecoration: "none", color: "black", fontWeight: "bold", paddingTop: "5px", fontSize: "2.3vw" }} activeClass="active-link">Add Advertisement</Link>
            <Link className='category_tab' to="/" style={{ textDecoration: "none", color: "black", fontWeight: "bold", paddingTop: "5px", fontSize: "2.3vw" }} onClick={() => localStorage.removeItem("newsapp_auth_token")} activeClass="active-link">logout</Link>
          </>
        }
      </div>}
      <div className="container-fluid main-div" style={{ width: '80%' }}>
        <div className='d-flex'>
          <div>
            <div className="d-flex" id='bhilaiTimeMainHead'>
              <Link to="/"><img style={{ width: "22vw", height: "10vw" }} src={anotherLogo} alt="Aap tak Chhattisgarh logo" /></Link>
            </div>
            <div className="d-flex" id='bhilaiTimeMainHead'>
              <h1 className='' style={{ fontWeight: "bold", fontSize: "1.5vw", marginTop: "-7.8vh", marginLeft: "4.3vw", color: "#000" }}>सच आप तक</h1>
            </div>
          </div>
          <div id="sidePhotoLogo">
            <img src={sublogo || adA1} alt="" style={{ width: "55vw", height: "18vh", marginTop: "1vh" }} />
          </div>
        </div>
        <div id='top_div' style={{ backgroundColor: "teal", height: '42vh', marginTop: '36px' }}>
          <div id="image_ad_div">
            <img id='first_ad' style={{ height: '33vh', width: "100%", padding: "10px" }} src={adA1} alt="" />
          </div>
          <div id='cat_div' className="d-flex" style={{ bottom: '0', alignItems: "flex-end", height: "8vh" }}>
            <Link className='category' to="/" style={{ marginLeft: '1%', color: "#ffff", fontSize: "0.9vw", textDecoration: "none", fontWeight: "bold" }} activeClass="active-link">Main Page</Link>
            {categories.map((category, index) => (
              <Link
                key={index}
                to={generateCategoryLink(category)}
                className='category'
                style={{ marginLeft: '1.3%', color: "#ffff", fontSize: "0.9vw", textDecoration: "none", fontWeight: "bold" }}
                activeClassName="active-link"
              >
                {category}
              </Link>
            ))}
            {!showAdminLinks ? null
              :
              <>
                <Link className='category' to="/addNews" style={{ marginLeft: '1.5%', color: "white", fontSize: "0.8vw", fontFamily: "", fontWeight: "bold", textDecoration: "none" }} activeClass="active-link">Add News</Link>
                <Link className='category' to="/addAd" style={{ marginLeft: '1.5%', color: "white", fontSize: "0.8vw", fontFamily: "", fontWeight: "bold", textDecoration: "none" }} activeClass="active-link">Add Advertisement</Link>
                <Link className='category' to="/" style={{ marginLeft: '1.5%', color: "white", fontSize: "0.8vw", fontFamily: "", fontWeight: "bold", textDecoration: "none" }} onClick={() => localStorage.removeItem("newsapp_auth_token")} activeClass="active-link">logout</Link>
              </>
            }
          </div>
        </div>
        <div id="top_story" className='d-flex' style={{ height: "6vh", marginTop: "1vh" }}>
          <div id="top_story_head_box" style={{ width: "10%", backgroundColor: "teal", height: "6vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <h6 style={{ fontSize: "1vw" }} className='text-white mt-2'>Top Stories</h6>
          </div>
          <div
            id="animated_headline_box"
            className='d-flex animated-headline-container ml-2'
            style={{ alignItems: "center", paddingTop: "4px", maxWidth: "89%", marginLeft: "1" }}>
            {headline.length > 0 && (
              <div className="animated-headline">
                {headline[currentHeadlineIndex]}
              </div>
            )}
          </div>
        </div>
        <img id='hidden_first_ad' style={{ display: "none" }} src={adA1} alt="" />
      </div>
    </div>
  )
}