import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import HiddenNavbar from '../Components/HiddenNavbar';
// import '../CSS/HiddenNavbar.css'; // Import the CSS file for styling
import Dev from '../assets/breaking_news.jpg';
import SS from '../assets/Screenshot from 2024-01-08 01-34-34.png';
import { Link, useNavigate } from 'react-router-dom';
// import '../CSS/Header.css'
import { Card, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/MainPage.css'
import Carousel from '../Components/Carousel';

export default function MainPage() {
  const yourDataArray = [
    {
      postId: 1,
      imageUrl: Dev,
      body: '!!Your internet is not working properly!!',
    },
    {
      postId: 2,
      imageUrl: Dev,
      body: '!!Your internet is not working properly!!',
    },
    {
      postId: 3,
      imageUrl: Dev,
      body: '!!Your internet is not working properly!!',
    },
    {
      postId: 4,
      imageUrl: Dev,
      body: '!!Your internet is not working properly!!',
    },
    // Add more objects as needed
  ];

  const [showHiddenNavbar, setShowHiddenNavbar] = useState(false);
  const [startIndex, setStartIndex] = useState(0); // Track the start index of the displayed cards
  const cardsPerPage = window.innerWidth <= 500 ? 1 : 3; // Number of cards to display per page
  const totalCards = yourDataArray.length; // Replace 'yourDataArray' with your actual array of data

  const truncateText = (text, maxWords) => {
    if (text) {
      const words = text.split(' ');

      if (words.length <= maxWords) {
        return text;
      }

      const truncatedText = words.slice(0, maxWords).join(' ');
      return `${truncatedText}...`;
    } else return text;
  };

  // Slice the array to get the current set of three cards
  const currentCards = yourDataArray.slice(startIndex, startIndex + cardsPerPage);





  // ************************************************APIs****************************************************************** 



  const [ads, setAds] = useState([]);
  const fetchAds = () => {
    fetch("https://d19rkiagm94rwl.cloudfront.net/api/ads/user/all")
      .then(response => {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        return response.json();
      })
      .then(data => {
        const adsObject = {};
        data.forEach(({ adsId, adsUrl }) => {
          adsObject[adsId] = adsUrl;
        });
        setAds(adsObject);
      })
      .catch(error => console.error('Error:', error));
  };

  const [latestNews, setLatestNews] = useState([]);
  const [politicsNews, setPoliticsNews] = useState([]);
  const [NagarNigam, setNagarNigam] = useState([]);
  const [covid, setCovid] = useState([]);
  const [entertainment, setEntertainment] = useState([]);
  const [education, setEducation] = useState([]);
  const [sports, setSports] = useState([]);
  const [international, setInternational] = useState([]);
  const fetchNews = () => {

    /*********************************Latest News**************************** */
    fetch("https://d19rkiagm94rwl.cloudfront.net/api/photos/news/latest").then(response => {
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      return response.json();
    })
      .then(data => setLatestNews(data))
      .catch(error => console.error('Error:', error));

  //  console.log(latestNews)

    /*******************************Politics********************************* */
    fetch("https://d19rkiagm94rwl.cloudfront.net/api/photos/news/get/Bhilai-Durg").then(response => {//Politics changed to Bhilai Durg
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      return response.json();
    })
      .then(data => setPoliticsNews(data))
      .catch(error => console.error('Error:', error));


    /*******************************Nagar-Nigam********************************* */
    fetch("https://d19rkiagm94rwl.cloudfront.net/api/photos/news/get/Politics").then(response => {//Bhilai Durg Changed to Politics
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      return response.json();
    })
      .then(data => setNagarNigam(data))
      .catch(error => console.error('Error:', error));


    /*******************************Covid********************************* */
    fetch("https://d19rkiagm94rwl.cloudfront.net/api/photos/news/get/Sports").then(response => {
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      return response.json();
    })
      .then(data => setCovid(data))
      .catch(error => console.error('Error:', error));



    /*******************************Entertainment********************************* */
    fetch("https://d19rkiagm94rwl.cloudfront.net/api/photos/news/get/CG").then(response => {
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      return response.json();
    })
      .then(data => setEntertainment(data))
      .catch(error => console.error('Error:', error));


    /*******************************Education********************************* */
    fetch("https://d19rkiagm94rwl.cloudfront.net/api/photos/news/get/Education").then(response => {
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      return response.json();
    })
      .then(data => setEducation(data))
      .catch(error => console.error('Error:', error));


    /*******************************Sports********************************* */
    fetch("https://d19rkiagm94rwl.cloudfront.net/api/photos/news/get/BSP").then(response => {
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      return response.json();
    })
      .then(data => setSports(data))
      .catch(error => console.error('Error:', error));


    /*******************************International********************************* */
    fetch("https://d19rkiagm94rwl.cloudfront.net/api/photos/news/get/National").then(response => {
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      return response.json();
    })
      .then(data => setInternational(data))
      .catch(error => console.error('Error:', error));
  }

  //*********************************************************Apis Over***************************************************************

  useEffect(() => {
    fetchNews();
    fetchAds();
    // console.log("latest news ", latestNews);
    const handleScroll = () => {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;

      // Adjust the scroll threshold as needed (20vh in this case)
      setShowHiddenNavbar(scrollPosition > 0.6 * window.innerHeight);
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigation = useNavigate();
  const navigateTo = (id) => {
    if (id) navigation(`/news/${id}`);
  }

  return (
    <div>
      <Header />
      <HiddenNavbar showFixed={showHiddenNavbar} />
      <div className="container-fluid main-div d-flex flex-column" style={{ width: '80%', marginBottom: "3vh" }}>
        <div id="another_box" style={{ marginTop: "7vh", backgroundColor: "" }}>
          <div className='d-flex mt-3' id='big_news_box' style={{ height: "55vh" }}>
            <div onClick={() => navigateTo(latestNews[0].postId)} id="largest_box" style={{ width: "32.5%", height: "100%", backgroundColor: "black" }}>
              {/* <img style={{ width: "100%", height: "100%" }} src={latestNews[0] ? latestNews[0].photoUrl : Dev} alt="" /> */}
              {latestNews[0] && latestNews[0].fileType === "Image" && (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={latestNews[0].photoUrl}
                  className='zoomable-image'
                  alt=""
                />
              )}

              {latestNews[0] && latestNews[0].fileType === "Video" && (
                <video
                  style={{ width: "100%", height: "100%" }}
                  src={latestNews[0].photoUrl}
                  controls
                >
                  Your browser does not support the video tag.
                </video>
              )}

              {latestNews[0] && latestNews[0].fileType === "Link" && (
                <iframe
                  title="Embedded Video"
                  width="100%"
                  height="100%"
                  src={latestNews[0].fileLink}
                  allowFullScreen
                ></iframe>
              )}

              {latestNews[0] && latestNews[0].fileType === "Pdf" && (
                <iframe
                  title="Pdf Viewer"
                  width="100%"
                  height="100%"
                  src={latestNews[0].photoUrl}
                  allowFullScreen
                ></iframe>
              )}

              {latestNews[0] === undefined && (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={Dev}
                  alt=""
                  className='zoomable-image'
                />
              )}

              <div className="overlay-text-1 d-flex flex-column">
                <p>{latestNews[0] ? latestNews[0].dateTime : "31 January, 2024"}</p>
                <Link onClick={() => navigateTo(latestNews[0].postId)} style={{ textDecoration: "none" }} className="active-hover"><h4 style={{ color: "white" }}>{truncateText(latestNews[0] ? latestNews[0].heading : "!!Your internet is not working properly!!", 10)}</h4></Link>
                {/* <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam cumque doloremque ex accusamus, vel soluta magni quasi enim minima! Temporibus eius dicta harum, esse, est magni hic corporis recusandae corrupti facilis omnis eum, iste assumenda distinctio! Eius nisi similique fugiat sapiente aut sit, delectus ab! Fuga illum pariatur tenetur reiciendis sed cumque odio repellat ad accusantium magni non, voluptates doloremque iste facere quia molestias maxime deleniti quos, eos quae enim quibusdam reprehenderit. Aperiam tempora similique officiis soluta voluptatem, amet sequi tenetur inventore voluptas, ipsum ex illum impedit quae quaerat! Recusandae?</h4> */}
              </div>
            </div>
            <div className='d-flex flex-column large_containing_box' style={{ width: "33.7%", height: "100%", backgroundColor: "white", marginLeft: "0.5%" }}>
              <div onClick={() => navigateTo(latestNews[1].postId)} className='half_boxes' style={{ width: "100%", height: "49.5%", backgroundColor: "black" }}>
                {/* <img style={{ width: "100%", height: "100%" }} src={latestNews[1] ? latestNews[1].photoUrl : Dev} alt="" /> */}
                {latestNews[1] && latestNews[1].fileType === "Image" && (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={latestNews[1].photoUrl}
                    className='zoomable-image'
                    alt=""
                  />
                )}

                {latestNews[1] && latestNews[1].fileType === "Video" && (
                  <video
                    style={{ width: "100%", height: "100%" }}
                    src={latestNews[1].photoUrl}
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                )}

                {latestNews[1] && latestNews[1].fileType === "Link" && (
                  <iframe
                    title="Embedded Video"
                    width="100%"
                    height="100%"
                    src={latestNews[1].fileLink}
                    allowFullScreen
                  ></iframe>
                )}

                {latestNews[1] && latestNews[1].fileType === "Pdf" && (
                  <iframe
                    title="Pdf Viewer"
                    width="100%"
                    height="100%"
                    src={latestNews[1].photoUrl}
                    allowFullScreen
                  ></iframe>
                )}

                {latestNews[1] === undefined && (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={Dev}
                    alt=""
                    className='zoomable-image'
                  />
                )}

                <div className="overlay-text d-flex flex-column">
                  <p>{latestNews[1] ? latestNews[1].dateTime : "31 January, 2024"}</p>
                  <Link onClick={() => navigateTo(latestNews[1].postId)} style={{ textDecoration: "none" }} className="active-hover"><h4 style={{ color: "white" }}>{truncateText(latestNews[1] ? latestNews[1].heading : "!!Your internet is not working properly!!", 10)}</h4></Link>
                  {/* <h4>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab vel porro debitis expedita atque mollitia maxime, maiores aliquid officiis soluta deleniti aut doloribus odio libero dolorem qui inventore. Quidem modi, inventore voluptates animi similique optio pariatur. Molestiae nulla nostrum ab, nobis maxime iure deserunt cumque qui voluptates quisquam necessitatibus eveniet saepe vel? Recusandae repellendus optio consequatur, quidem veritatis at? Hic minima accusantium officia, ratione doloribus officiis nihil consequuntur recusandae laudantium explicabo accusamus eius, voluptatibus ut delectus blanditiis sit aspernatur exercitationem reiciendis? Alias minima perferendis ratione aspernatur hic exercitationem odio qui.</h4> */}
                </div>
              </div>
              <div onClick={() => navigateTo(latestNews[2].postId)} className='half_boxes' style={{ width: "100%", height: "49.5%", marginTop: "1%", backgroundColor: "black" }}>
                {/* <img style={{ width: "100%", height: "100%" }} src={latestNews[2] ? latestNews[2].photoUrl : Dev} alt="" /> */}
                {latestNews[2] && latestNews[2].fileType === "Image" && (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    className='zoomable-image'
                    src={latestNews[2].photoUrl}
                    alt=""
                  />
                )}

                {latestNews[2] && latestNews[2].fileType === "Video" && (
                  <video
                    style={{ width: "100%", height: "100%" }}
                    src={latestNews[2].photoUrl}
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                )}

                {latestNews[2] && latestNews[2].fileType === "Link" && (
                  <iframe
                    title="Embedded Video"
                    width="100%"
                    height="100%"
                    src={latestNews[2].fileLink}
                    allowFullScreen
                  ></iframe>
                )}

                {latestNews[2] && latestNews[2].fileType === "Pdf" && (
                  <iframe
                    title="Pdf Viewer"
                    width="100%"
                    height="100%"
                    src={latestNews[2].photoUrl}
                    allowFullScreen
                  ></iframe>
                )}

                {latestNews[2] === undefined && (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={Dev}
                    alt=""
                    className='zoomable-image'
                  />
                )}

                <div className="overlay-text d-flex flex-column">
                  <p>{latestNews[2] ? latestNews[2].dateTime : "31 January, 2024"}</p>
                  <Link onClick={() => navigateTo(latestNews[2].postId)} style={{ textDecoration: "none" }} className="active-hover"><h4 style={{ color: "white" }}>{truncateText(latestNews[2] ? latestNews[2].heading : "!!Your internet is not working properly!!", 10)}</h4></Link>
                  {/* <h4>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab vel porro debitis expedita atque mollitia maxime, maiores aliquid officiis soluta deleniti aut doloribus odio libero dolorem qui inventore. Quidem modi, inventore voluptates animi similique optio pariatur. Molestiae nulla nostrum ab, nobis maxime iure deserunt cumque qui voluptates quisquam necessitatibus eveniet saepe vel? Recusandae repellendus optio consequatur, quidem veritatis at? Hic minima accusantium officia, ratione doloribus officiis nihil consequuntur recusandae laudantium explicabo accusamus eius, voluptatibus ut delectus blanditiis sit aspernatur exercitationem reiciendis? Alias minima perferendis ratione aspernatur hic exercitationem odio qui.</h4> */}
                </div>
              </div>
            </div>
            <div className='d-flex flex-column large_containing_box' style={{ width: "33.7%", marginLeft: "0.5%", height: "100%", backgroundColor: "white" }}>
              <div onClick={() => navigateTo(latestNews[3].postId)} className='half_boxes' style={{ width: "100%", height: "49.5%", backgroundColor: "black" }}>
                {/* <img style={{ width: "100%", height: "100%" }} src={latestNews[3] ? latestNews[3].photoUrl : Dev} alt="" /> */}
                {latestNews[3] && latestNews[3].fileType === "Image" && (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={latestNews[3].photoUrl}
                    alt=""
                    className='zoomable-image'
                  />
                )}

                {latestNews[3] && latestNews[3].fileType === "Video" && (
                  <video
                    style={{ width: "100%", height: "100%" }}
                    src={latestNews[3].photoUrl}
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                )}

                {latestNews[3] && latestNews[3].fileType === "Link" && (
                  <iframe
                    title="Embedded Video"
                    width="100%"
                    height="100%"
                    src={latestNews[3].fileLink}
                    allowFullScreen
                  ></iframe>
                )}

                {latestNews[3] && latestNews[3].fileType === "Pdf" && (
                  <iframe
                    title="Pdf Viewer"
                    width="100%"
                    height="100%"
                    src={latestNews[3].photoUrl}
                    allowFullScreen
                  ></iframe>
                )}

                {latestNews[3] === undefined && (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={Dev}
                    alt=""
                    className='zoomable-image'
                  />
                )}

                <div className="overlay-text d-flex flex-column">
                  <p>{latestNews[3] ? latestNews[3].dateTime : "31 January, 2024"}</p>
                  <Link onClick={() => navigateTo(latestNews[3].postId)} style={{ textDecoration: "none" }} className="active-hover"><h4 style={{ color: "white" }}>{truncateText(latestNews[3] ? latestNews[3].heading : "!!Your internet is not working properly!!", 10)}</h4></Link>
                  {/* <h4>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab vel porro debitis expedita atque mollitia maxime, maiores aliquid officiis soluta deleniti aut doloribus odio libero dolorem qui inventore. Quidem modi, inventore voluptates animi similique optio pariatur. Molestiae nulla nostrum ab, nobis maxime iure deserunt cumque qui voluptates quisquam necessitatibus eveniet saepe vel? Recusandae repellendus optio consequatur, quidem veritatis at? Hic minima accusantium officia, ratione doloribus officiis nihil consequuntur recusandae laudantium explicabo accusamus eius, voluptatibus ut delectus blanditiis sit aspernatur exercitationem reiciendis? Alias minima perferendis ratione aspernatur hic exercitationem odio qui.</h4> */}
                </div>
              </div>
              <div onClick={() => navigateTo(latestNews[4].postId)} className='half_boxes' style={{ width: "100%", height: "49.5%", marginTop: "1%", backgroundColor: "black" }}>
                {/* <img style={{ width: "100%", height: "100%" }} src={latestNews[4] ? latestNews[4].photoUrl : Dev} alt="" /> */}
                {latestNews[4] && latestNews[4].fileType === "Image" && (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={latestNews[4].photoUrl}
                    alt=""
                    className='zoomable-image'
                  />
                )}

                {latestNews[4] && latestNews[4].fileType === "Video" && (
                  <video
                    style={{ width: "100%", height: "100%" }}
                    src={latestNews[4].photoUrl}
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                )}

                {latestNews[4] && latestNews[4].fileType === "Link" && (
                  <iframe
                    title="Embedded Video"
                    width="100%"
                    height="100%"
                    src={latestNews[4].fileLink}
                    allowFullScreen
                  ></iframe>
                )}

                {latestNews[4] && latestNews[4].fileType === "Pdf" && (
                  <iframe
                    title="Pdf Viewer"
                    width="100%"
                    height="100%"
                    src={latestNews[4].photoUrl}
                    allowFullScreen
                  ></iframe>
                )}

                {latestNews[4] === undefined && (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={Dev}
                    alt=""
                    className='zoomable-image'
                  />
                )}

                <div className="overlay-text d-flex flex-column">
                  <p>{latestNews[4] ? latestNews[4].dateTime : "31 January, 2024"}</p>
                  <Link onClick={() => navigateTo(latestNews[4].postId)} style={{ textDecoration: "none" }} className="active-hover"><h4 style={{ color: "white" }}>{truncateText(latestNews[4] ? latestNews[4].heading : "!!Your internet is not working properly!!", 10)}</h4></Link>
                  {/* <h4>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab vel porro debitis expedita atque mollitia maxime, maiores aliquid officiis soluta deleniti aut doloribus odio libero dolorem qui inventore. Quidem modi, inventore voluptates animi similique optio pariatur. Molestiae nulla nostrum ab, nobis maxime iure deserunt cumque qui voluptates quisquam necessitatibus eveniet saepe vel? Recusandae repellendus optio consequatur, quidem veritatis at? Hic minima accusantium officia, ratione doloribus officiis nihil consequuntur recusandae laudantium explicabo accusamus eius, voluptatibus ut delectus blanditiis sit aspernatur exercitationem reiciendis? Alias minima perferendis ratione aspernatur hic exercitationem odio qui.</h4> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="divided-box" className='d-flex mt-5'>
          <div id="divided_box_1" style={{ width: "70%", backgroundColor: "" }}>
            <div className="category_heading_box">
              <div className="d-flex justify-content-between">
                <div className="cat-box d-flex" style={{ width: "15%", justifyContent: "center", alignItems: "center", backgroundColor: "red", color: "white", padding: "8px" }}>
                  <h5 style={{ fontSize: "1vw" }}>Latest News</h5>
                </div>
                <h5 onClick={() => navigation("/cat?category=Latest-News")} style={{ color: "darkblue", textDecoration: "none", marginTop: "15px", marginRight: "25px", cursor: "pointer" }}>More -&gt;</h5>
              </div>
              <div style={{ width: "100%", height: "0.3vh", backgroundColor: "red" }}></div>
            </div>
            <div id="latest_news_box" className='d-flex mt-3'>
              <div onClick={() => navigateTo(latestNews[0].postId)} id="box1" style={{ width: "49%", height: "55vh", backgroundColor: "black", }}>
                {/* <img style={{ width: "100%", height: "100%", opacity: "0.8" }} src={latestNews[0] ? latestNews[0].photoUrl : Dev} alt="" /> */}
                {latestNews[0] && latestNews[0].fileType === "Image" && (
                  <img
                    style={{ width: "100%", height: "100%", opacity: "0.8" }}
                    src={latestNews[0].photoUrl}
                    alt=""
                    className='zoomable-image'
                  />
                )}

                {latestNews[0] && latestNews[0].fileType === "Video" && (
                  <video
                    style={{ width: "100%", height: "100%", opacity: "0.8" }}
                    src={latestNews[0].photoUrl}
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                )}

                {latestNews[0] && latestNews[0].fileType === "Link" && (
                  <iframe
                    title="Embedded Video"
                    width="100%"
                    height="100%"
                    src={latestNews[0].fileLink}
                    allowFullScreen
                  ></iframe>
                )}

                {latestNews[0] && latestNews[0].fileType === "Pdf" && (
                  <iframe
                    title="Pdf Viewer"
                    width="100%"
                    height="100%"
                    src={latestNews[0].photoUrl}
                    allowFullScreen
                  ></iframe>
                )}

                {latestNews[0] === undefined && (
                  <img
                    style={{ width: "100%", height: "100%", opacity: "0.8" }}
                    src={Dev}
                    alt=""
                    className='zoomable-image'
                  />
                )}

                <div className="overlay-text-1">
                  <p>{latestNews[0] ? latestNews[0].dateTime : "31 January, 2024"}</p>
                  <Link onClick={() => navigateTo(latestNews[0].postId)} style={{ textDecoration: "none" }} className="active-hover"><h4 style={{ color: "white" }}>{truncateText(latestNews[0] ? latestNews[0].heading : "!!Your internet is not working properly!!", 10)}</h4></Link>
                </div>
              </div>
              <div id="box2" style={{ width: "49%", maxHeight: "55vh", marginLeft: "2%" }}>
                <div onClick={() => navigateTo(latestNews[1].postId)} className="card" style={{ height: "31%", margin: "0%", padding: "0%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={latestNews[1] ? latestNews[1].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "100%" }} /> */}
                      {latestNews[1] && latestNews[1].fileType === "Image" && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={latestNews[1].photoUrl}
                          alt=""
                          className='zoomable-image'
                        />
                      )}

                      {latestNews[1] && latestNews[1].fileType === "Video" && (
                        <video
                          style={{ width: "100%", height: "100%" }}
                          src={latestNews[1].photoUrl}
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {latestNews[1] && latestNews[1].fileType === "Link" && (
                        <iframe
                          title="Embedded Video"
                          width="100%"
                          height="100%"
                          src={latestNews[1].fileLink}
                          allowFullScreen
                        ></iframe>
                      )}

                      {latestNews[1] && latestNews[1].fileType === "Pdf" && (
                        <iframe
                          title="Pdf Viewer"
                          width="100%"
                          height="100%"
                          src={latestNews[1].photoUrl}
                          allowFullScreen
                        ></iframe>
                      )}

                      {latestNews[1] === undefined && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={Dev}
                          alt=""
                          className='zoomable-image'
                        />
                      )}

                    </div>
                    <div style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{latestNews[1] ? latestNews[1].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(latestNews[1].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text d-flex">{truncateText(latestNews[1] ? latestNews[1].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>


                <div onClick={() => navigateTo(latestNews[2].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={latestNews[2] ? latestNews[2].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "100%" }} /> */}
                      {latestNews[2] && latestNews[2].fileType === "Image" && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={latestNews[2].photoUrl}
                          alt=""
                          className='zoomable-image'
                        />
                      )}

                      {latestNews[2] && latestNews[2].fileType === "Video" && (
                        <video
                          style={{ width: "100%", height: "100%" }}
                          src={latestNews[2].photoUrl}
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {latestNews[2] && latestNews[2].fileType === "Link" && (
                        <iframe
                          title="Embedded Video"
                          width="100%"
                          height="100%"
                          src={latestNews[2].fileLink}
                          allowFullScreen
                        ></iframe>
                      )}

                      {latestNews[2] && latestNews[2].fileType === "Pdf" && (
                        <iframe
                          title="Pdf Viewer"
                          width="100%"
                          height="100%"
                          src={latestNews[2].photoUrl}
                          allowFullScreen
                        ></iframe>
                      )}

                      {latestNews[2] === undefined && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={Dev}
                          alt=""
                          className='zoomable-image'
                        />
                      )}
                    </div>
                    <div style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{latestNews[2] ? latestNews[2].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(latestNews[2].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text d-flex">{truncateText(latestNews[2] ? latestNews[2].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => navigateTo(latestNews[3].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={latestNews[3] ? latestNews[3].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "100%" }} /> */}
                      {latestNews[3] && latestNews[3].fileType === "Image" && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={latestNews[3].photoUrl}
                          alt=""
                          className='zoomable-image'
                        />
                      )}

                      {latestNews[3] && latestNews[3].fileType === "Video" && (
                        <video
                          style={{ width: "100%", height: "100%" }}
                          src={latestNews[3].photoUrl}
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {latestNews[3] && latestNews[3].fileType === "Link" && (
                        <iframe
                          title="Embedded Video"
                          width="100%"
                          height="100%"
                          src={latestNews[3].fileLink}
                          allowFullScreen
                        ></iframe>
                      )}

                      {latestNews[3] && latestNews[3].fileType === "Pdf" && (
                        <iframe
                          title="Pdf Viewer"
                          width="100%"
                          height="100%"
                          src={latestNews[3].photoUrl}
                          allowFullScreen
                        ></iframe>
                      )}

                      {latestNews[3] === undefined && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={Dev}
                          alt=""
                          className='zoomable-image'

                        />
                      )}
                    </div>
                    <div style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{latestNews[3] ? latestNews[3].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(latestNews[3].postId)} style={{ textDecoration: "none" }} ><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text d-flex">{truncateText(latestNews[3] ? latestNews[3].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div id="adOneForMobile" style={{ width: "30%", backgroundColor: "", marginLeft: "1%", display: "none" }}>
              <img src={ads[0] ? ads[0] : SS} style={{ width: "90%", marginLeft: "10%", height: "auto", marginTop: "4vh" }} alt="" />
            </div>


            <div className="category_heading_box" style={{ marginTop: "10vh" }}>
              <div className="cat-box d-flex" style={{ width: "15%", justifyContent: "center", alignItems: "center", backgroundColor: "#0693e3", color: "white", padding: "8px" }}>
                <h5 style={{ fontSize: "1vw" }}>Bhilai-Durg</h5>
              </div>
              <div style={{ width: "100%", height: "0.3vh", backgroundColor: "#0693e3" }}></div>
            </div>
            <div id="politics_news_box" className='d-flex mt-3'>
              <div onClick={() => navigateTo(politicsNews[0].postId)} id="box1" style={{ width: "49%", height: "55vh", backgroundColor: "black", }}>
                {/* <img style={{ width: "100%", height: "100%", opacity: "0.8" }} src={politicsNews[0] ? politicsNews[0].photoUrl : Dev} alt="" /> */}
                {politicsNews[0] && politicsNews[0].fileType === "Image" && (
                  <img
                    style={{ width: "100%", height: "100%", opacity: "0.8" }}
                    src={politicsNews[0].photoUrl}
                    alt=""
                    className='zoomable-image'
                  />
                )}

                {politicsNews[0] && politicsNews[0].fileType === "Video" && (
                  <video
                    style={{ width: "100%", height: "100%", opacity: "0.8" }}
                    src={politicsNews[0].photoUrl}
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                )}

                {politicsNews[0] && politicsNews[0].fileType === "Link" && (
                  <iframe
                    title="Embedded Video"
                    width="100%"
                    height="100%"
                    src={politicsNews[0].fileLink}
                    allowFullScreen
                  ></iframe>
                )}

                {politicsNews[0] && politicsNews[0].fileType === "Pdf" && (
                  <iframe
                    title="Pdf Viewer"
                    width="100%"
                    height="100%"
                    src={politicsNews[0].photoUrl}
                    allowFullScreen
                  ></iframe>
                )}

                {politicsNews[0] === undefined && (
                  <img
                    style={{ width: "100%", height: "100%", opacity: "0.8" }}
                    src={Dev}
                    alt=""
                    className='zoomable-image'
                  />
                )}
                <div className="overlay-text-1">
                  <p>{politicsNews[0] ? politicsNews[0].dateTime : "31 January, 2024"}</p>
                  <Link onClick={() => navigateTo(politicsNews[0].postId)} style={{ textDecoration: "none" }} className="active-hover"><h4 style={{ color: "white" }}>{truncateText(politicsNews[0] ? politicsNews[0].heading : "!!Your internet is not working properly!!", 9)}</h4></Link>

                </div>
              </div>
              <div id="box2" style={{ width: "49%", maxHeight: "55vh", marginLeft: "2%" }}>
                <div onClick={() => navigateTo(politicsNews[1].postId)} className="card" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={politicsNews[1] ? politicsNews[1].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "100%" }} /> */}
                      {politicsNews[1] && politicsNews[1].fileType === "Image" && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={politicsNews[1].photoUrl}
                          alt=""
                          className='zoomable-image'
                        />
                      )}

                      {politicsNews[1] && politicsNews[1].fileType === "Video" && (
                        <video
                          style={{ width: "100%", height: "100%" }}
                          src={politicsNews[1].photoUrl}
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {politicsNews[1] && politicsNews[1].fileType === "Link" && (
                        <iframe
                          title="Embedded Video"
                          width="100%"
                          height="100%"
                          src={politicsNews[1].fileLink}
                          allowFullScreen
                        ></iframe>
                      )}

                      {politicsNews[1] && politicsNews[1].fileType === "Pdf" && (
                        <iframe
                          title="Pdf Viewer"
                          width="100%"
                          height="100%"
                          src={politicsNews[1].photoUrl}
                          allowFullScreen
                        ></iframe>
                      )}

                      {politicsNews[1] === undefined && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={Dev}
                          alt=""
                          className='zoomable-image'
                        />
                      )}
                    </div>
                    <div style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{politicsNews[1] ? politicsNews[1].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(politicsNews[1].postId)} style={{ textDecoration: "none" }} ><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text d-flex">{truncateText(politicsNews[1] ? politicsNews[1].heading : "!!Your internet is not working properly!!", 13)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>


                <div onClick={() => navigateTo(politicsNews[2].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={politicsNews[2] ? politicsNews[2].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "100%" }} /> */}
                      {politicsNews[2] && politicsNews[2].fileType === "Image" && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={politicsNews[2].photoUrl}
                          alt=""
                          className='zoomable-image'
                        />
                      )}

                      {politicsNews[2] && politicsNews[2].fileType === "Video" && (
                        <video
                          style={{ width: "100%", height: "100%" }}
                          src={politicsNews[2].photoUrl}
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {politicsNews[2] && politicsNews[2].fileType === "Link" && (
                        <iframe
                          title="Embedded Video"
                          width="100%"
                          height="100%"
                          src={politicsNews[2].fileLink}
                          allowFullScreen
                        ></iframe>
                      )}

                      {politicsNews[2] && politicsNews[2].fileType === "Pdf" && (
                        <iframe
                          title="Pdf Viewer"
                          width="100%"
                          height="100%"
                          src={politicsNews[2].photoUrl}
                          allowFullScreen
                        ></iframe>
                      )}

                      {politicsNews[2] === undefined && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={Dev}
                          alt=""
                          className='zoomable-image'
                        />
                      )}
                    </div>
                    <div style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{politicsNews[2] ? politicsNews[2].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(politicsNews[2].postId)} style={{ textDecoration: "none" }} ><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text d-flex">{truncateText(politicsNews[2] ? politicsNews[2].heading : "!!Your internet is not working properly!!", 13)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => navigateTo(politicsNews[3].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={politicsNews[3] ? politicsNews[3].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "100%" }} /> */}
                      {politicsNews[3] && politicsNews[3].fileType === "Image" && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={politicsNews[3].photoUrl}
                          alt=""
                          className='zoomable-image'
                        />
                      )}

                      {politicsNews[3] && politicsNews[3].fileType === "Video" && (
                        <video
                          style={{ width: "100%", height: "100%" }}
                          src={politicsNews[3].photoUrl}
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {politicsNews[3] && politicsNews[3].fileType === "Link" && (
                        <iframe
                          title="Embedded Video"
                          width="100%"
                          height="100%"
                          src={politicsNews[3].fileLink}
                          allowFullScreen
                        ></iframe>
                      )}

                      {politicsNews[3] && politicsNews[3].fileType === "Pdf" && (
                        <iframe
                          title="Pdf Viewer"
                          width="100%"
                          height="100%"
                          src={politicsNews[3].photoUrl}
                          allowFullScreen
                        ></iframe>
                      )}

                      {politicsNews[3] === undefined && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={Dev}
                          alt=""
                          className='zoomable-image'
                        />
                      )}
                    </div>
                    <div style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{politicsNews[3] ? politicsNews[3].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(politicsNews[3].postId)} style={{ textDecoration: "none" }} ><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text d-flex">{truncateText(politicsNews[3] ? politicsNews[3].heading : "!!Your internet is not working properly!!", 13)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <div className="category_heading_box" style={{ marginTop: "10vh" }}>
              <div className="cat-box d-flex" style={{ width: "15%", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(255,105,0,1)", color: "white", padding: "8px" }}>
                <h5 style={{ fontSize: "1vw" }}>Politics</h5>
              </div>
              <div style={{ width: "100%", height: "0.3vh", backgroundColor: "rgba(255,105,0,1)" }}></div>
            </div>
            <div id="nagar_nigam_news_box" className='d-flex mt-3'>
              <div onClick={() => navigateTo(NagarNigam[0].postId)} id="box1" style={{ width: "49%", height: "55vh", backgroundColor: "black", }}>
                {/* <img style={{ width: "100%", height: "100%", opacity: "0.8" }} src={NagarNigam[0] ? NagarNigam[0].photoUrl : Dev} alt="" /> */}
                {NagarNigam[0] && NagarNigam[0].fileType === "Image" && (
                  <img
                    style={{ width: "100%", height: "100%", opacity: "0.8" }}
                    src={NagarNigam[0].photoUrl}
                    alt=""
                    className='zoomable-image'
                  />
                )}

                {NagarNigam[0] && NagarNigam[0].fileType === "Video" && (
                  <video
                    style={{ width: "100%", height: "100%", opacity: "0.8" }}
                    src={NagarNigam[0].photoUrl}
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                )}

                {NagarNigam[0] && NagarNigam[0].fileType === "Link" && (
                  <iframe
                    title="Embedded Video"
                    width="100%"
                    height="100%"
                    src={NagarNigam[0].fileLink}
                    allowFullScreen
                  ></iframe>
                )}

                {NagarNigam[0] && NagarNigam[0].fileType === "Pdf" && (
                  <iframe
                    title="Pdf Viewer"
                    width="100%"
                    height="100%"
                    src={NagarNigam[0].photoUrl}
                    allowFullScreen
                  ></iframe>
                )}

                {NagarNigam[0] === undefined && (
                  <img
                    style={{ width: "100%", height: "100%", opacity: "0.8" }}
                    src={Dev}
                    alt=""
                    className='zoomable-image'
                  />
                )}
                <div className="overlay-text-1">
                  <p>{NagarNigam[0] ? NagarNigam[0].dateTime : "31 January, 2024"}</p>
                  <Link onClick={() => navigateTo(NagarNigam[0].postId)} style={{ textDecoration: "none" }} className="active-hover"><h4 style={{ color: "white" }}>{truncateText(NagarNigam[0] ? NagarNigam[0].heading : "!!Your internet is not working properly!!", 9)}</h4></Link>
                </div>
              </div>
              <div id="box2" style={{ width: "49%", maxHeight: "55vh", marginLeft: "2%" }}>
                <div onClick={() => navigateTo(NagarNigam[1].postId)} className="card" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={NagarNigam[1] ? NagarNigam[1].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "100%" }} /> */}
                      {NagarNigam[1] && NagarNigam[1].fileType === "Image" && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={NagarNigam[1].photoUrl}
                          alt=""
                          className='zoomable-image'
                        />
                      )}

                      {NagarNigam[1] && NagarNigam[1].fileType === "Video" && (
                        <video
                          style={{ width: "100%", height: "100%" }}
                          src={NagarNigam[1].photoUrl}
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {NagarNigam[1] && NagarNigam[1].fileType === "Link" && (
                        <iframe
                          title="Embedded Video"
                          width="100%"
                          height="100%"
                          src={NagarNigam[1].fileLink}
                          allowFullScreen
                        ></iframe>
                      )}

                      {NagarNigam[1] && NagarNigam[1].fileType === "Pdf" && (
                        <iframe
                          title="Pdf Viewer"
                          width="100%"
                          height="100%"
                          src={NagarNigam[1].photoUrl}
                          allowFullScreen
                        ></iframe>
                      )}

                      {NagarNigam[1] === undefined && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={Dev}
                          alt=""
                          className='zoomable-image'
                        />
                      )}
                    </div>
                    <div style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{NagarNigam[1] ? NagarNigam[1].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(NagarNigam[1].postId)} style={{ textDecoration: "none" }} ><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text d-flex">{truncateText(NagarNigam[1] ? NagarNigam[1].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>


                <div onClick={() => navigateTo(NagarNigam[2].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={NagarNigam[2] ? NagarNigam[2].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "100%" }} /> */}
                      {NagarNigam[2] && NagarNigam[2].fileType === "Image" && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={NagarNigam[2].photoUrl}
                          alt=""
                          className='zoomable-image'
                        />
                      )}

                      {NagarNigam[2] && NagarNigam[2].fileType === "Video" && (
                        <video
                          style={{ width: "100%", height: "100%" }}
                          src={NagarNigam[2].photoUrl}
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {NagarNigam[2] && NagarNigam[2].fileType === "Link" && (
                        <iframe
                          title="Embedded Video"
                          width="100%"
                          height="100%"
                          src={NagarNigam[2].fileLink}
                          allowFullScreen
                        ></iframe>
                      )}

                      {NagarNigam[2] && NagarNigam[2].fileType === "Pdf" && (
                        <iframe
                          title="Pdf Viewer"
                          width="100%"
                          height="100%"
                          src={NagarNigam[2].photoUrl}
                          allowFullScreen
                        ></iframe>
                      )}

                      {NagarNigam[2] === undefined && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={Dev}
                          alt=""
                          className='zoomable-image'
                        />
                      )}
                    </div>
                    <div style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{NagarNigam[2] ? NagarNigam[2].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(NagarNigam[2].postId)} style={{ textDecoration: "none" }} ><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text d-flex">{truncateText(NagarNigam[2] ? NagarNigam[2].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => navigateTo(NagarNigam[3].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={NagarNigam[3] ? NagarNigam[3].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "100%" }} /> */}
                      {NagarNigam[3] && NagarNigam[3].fileType === "Image" && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={NagarNigam[3].photoUrl}
                          alt=""
                          className='zoomable-image'
                        />
                      )}

                      {NagarNigam[3] && NagarNigam[3].fileType === "Video" && (
                        <video
                          style={{ width: "100%", height: "100%" }}
                          src={NagarNigam[3].photoUrl}
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {NagarNigam[3] && NagarNigam[3].fileType === "Link" && (
                        <iframe
                          title="Embedded Video"
                          width="100%"
                          height="100%"
                          src={NagarNigam[3].fileLink}
                          allowFullScreen
                        ></iframe>
                      )}

                      {NagarNigam[3] && NagarNigam[3].fileType === "Pdf" && (
                        <iframe
                          title="Pdf Viewer"
                          width="100%"
                          height="100%"
                          src={NagarNigam[3].photoUrl}
                          allowFullScreen
                        ></iframe>
                      )}

                      {NagarNigam[3] === undefined && (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={Dev}
                          alt=""
                          className='zoomable-image'
                        />
                      )}
                    </div>
                    <div style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{NagarNigam[3] ? NagarNigam[3].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(NagarNigam[3].postId)} style={{ textDecoration: "none" }} ><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text d-flex">{truncateText(NagarNigam[3] ? NagarNigam[3].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>
          <div id="divided_box_2" style={{ width: "30%", backgroundColor: "" }}>
            <div style={{ marginLeft: "10%" }}>
              <Carousel />
            </div>
            <img src={ads[2] ? ads[2] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "9vh" }} alt="" />
            <img src={ads[3] ? ads[3] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "9vh" }} alt="" />
            <img src={ads[4] ? ads[4] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "13vh" }} alt="" />
            {/* <img src={SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "9vh" }} alt="" /> */}
          </div>
        </div>





        <div id="another_box_1" className='mt-5 mb-3'>
          <div className="category_heading_box" style={{ marginTop: "10vh" }}>
            <div className="cat-box d-flex" style={{ width: "15%", justifyContent: "center", alignItems: "center", backgroundColor: "#9b51e0", color: "white", padding: "8px" }}>
              <h5 style={{ fontSize: "1vw" }}>Sports</h5>
            </div>
            <div style={{ width: "100%", height: "0.3vh", backgroundColor: "#9b51e0" }}></div>
          </div>
          <div id="covid_news_box" className='mt-3 mb-4'>
            <div className="mt-5">
              <Row>
                {currentCards.map((card, index) => (
                  <Col key={card.postId} id='covid_row' md={4} className="mb-4">
                    <Card onClick={() => navigateTo(covid[index].postId)} className='covid_news_box_card mt-4' style={{ height: "100%" }}>

                      {covid[index] === undefined && (
                        <Card.Img
                          className='covid_news_box_image zoomable-image'
                          variant="top"
                          src={Dev}
                          style={{ height: "80%" }}
                        />
                      )}


                      {covid[index]?.fileType === "Image" && (
                        <Card.Img
                          className='covid_news_box_image zoomable-image'
                          variant="top"
                          src={covid[index].photoUrl || card.imageUrl}
                          style={{ height: "300px" }}
                        />
                      )}

                      {covid[index]?.fileType === "Video" && (
                        <video
                          src={covid[index].photoUrl || Dev}
                          style={{ width: "100%", height: "80%" }}
                          className='covid_news_box_image'
                          controls
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {covid[index]?.fileType === "Link" && (
                        <iframe
                          title="Embedded Video"
                          width="100%"
                          height="80%"
                          src={covid[index].fileLink || Dev}
                          allowFullScreen
                          className='covid_news_box_image'
                        ></iframe>
                      )}

                      {covid[index]?.fileType === "Pdf" && (
                        <iframe
                          title="Pdf Viewer"
                          width="100%"
                          height="80%"
                          src={covid[index].photoUrl}
                          allowFullScreen
                          className='covid_news_box_image'
                        ></iframe>
                      )}

                      <Card.Body >
                        <Card.Text className='covid_news_box_date' style={{ fontSize: "0.8vw", color: "grey" }}>{covid[index] ? covid[index].dateTime : "31 January, 2024"}</Card.Text>
                        <Card.Text onClick={() => navigateTo(covid[index].postId)} className='covid_news_box_heading' style={{ fontSize: "0.8vw" }}><Link style={{ textDecoration: "none" }}>{truncateText(covid[index] ? covid[index].heading : card.body, 14)}</Link></Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
              {/* <div className="mt-2 mb-5 text-center">
                <Button variant="secondary" onClick={handlePrev} disabled={startIndex === 0}>
                  Previous
                </Button>{' '}
                <Button variant="secondary" onClick={handleNext} disabled={startIndex + cardsPerPage >= totalCards}>
                  Next
                </Button>
              </div> */}
            </div>
          </div>
        </div>



        <div id="another_box_2" className='mt-5 d-flex'>

          <div id='entertainment_box' style={{ width: "32.5%", height: "65vh", backgroundColor: "" }}>
            <div className="cat-box d-flex" style={{ width: "29%", justifyContent: "center", alignItems: "center", backgroundColor: "purple", color: "white", padding: "8px" }}>
              <h5 style={{ fontSize: "1vw" }}>CG</h5>
            </div>
            <div style={{ width: "100%", height: "0.3vh", backgroundColor: "purple" }}></div>

            <div id="entertainment_news_box" className='mt-3'>
              <div onClick={() => navigateTo(entertainment[0].postId)} className="cat-breaking-news" style={{ backgroundColor: "black" }}>
                {/* <img src={entertainment[0] ? entertainment[0].photoUrl : Dev} className='news_box_image_ees' style={{ width: "100%", height: "30vh", opacity: "0.8" }} alt="" /> */}
                {

                  entertainment[0] &&
                  entertainment[0].fileType === "Image" && (
                    <img
                      style={{ width: "100%", height: "30vh", opacity: "0.8" }}
                      src={

                        entertainment[0].photoUrl}
                      alt=""
                      className='news_box_image_ees zoomable-image'
                    />
                  )}

                {

                  entertainment[0] &&

                  entertainment[0].fileType === "Video" && (
                    <video
                      style={{ width: "100%", height: "30vh", opacity: "0.8" }}
                      src={

                        entertainment[0].photoUrl}
                      controls
                      className='news_box_image_ees'
                    >
                      Your browser does not support the video tag.
                    </video>
                  )}

                {

                  entertainment[0] &&

                  entertainment[0].fileType === "Link" && (
                    <iframe
                      title="Embedded Video"
                      width="100%"
                      // height="30vh"
                      src={

                        entertainment[0].fileLink}
                      allowFullScreen
                      className='news_box_image_ees'
                    ></iframe>
                  )}

                {

                  entertainment[0] &&

                  entertainment[0].fileType === "Pdf" && (
                    <iframe
                      title="Pdf Viewer"
                      width="100%"
                      height="30vh"
                      src={

                        entertainment[0].photoUrl}
                      allowFullScreen
                      className='news_box_image_ees'
                    ></iframe>
                  )}

                {

                  entertainment[0] === undefined && (
                    <img
                      style={{ width: "100%", height: "30vh", opacity: "0.8" }}
                      src={Dev}
                      className='news_box_image_ees zoomable-image'
                      alt=""
                    />
                  )}
                <div className="overlay-text-2">
                  <p>{entertainment[0] ? entertainment[0].dateTime : "31 January, 2024"}</p>
                  <Link onClick={() => navigateTo(entertainment[0].postId)} style={{ textDecoration: "none", color: "white" }} className="active-hover"><h4>{truncateText(entertainment[0] ? entertainment[0].heading : "!!Your internet is not working properly!!", 7)}</h4></Link>
                </div>
              </div>
              <div id="box2" style={{ width: "100%", maxHeight: "55vh", marginLeft: "0%", marginTop: "2vh" }}>
                <div onClick={() => navigateTo(entertainment[1].postId)} className="card" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={entertainment[1] ? entertainment[1].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "15vh" }} /> */}
                      {

                        entertainment[1] &&
                        entertainment[1].fileType === "Image" && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              entertainment[1].photoUrl}
                            alt=""
                            className='card-img zoomable-image'
                          />
                        )}

                      {

                        entertainment[1] &&

                        entertainment[1].fileType === "Video" && (
                          <video
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              entertainment[1].photoUrl}
                            controls
                            className='card-img'
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}

                      {

                        entertainment[1] &&

                        entertainment[1].fileType === "Link" && (
                          <iframe
                            title="Embedded Video"
                            width="100%"
                            height="15vh"
                            src={

                              entertainment[1].fileLink}
                            allowFullScreen
                            className='card-img'
                          ></iframe>
                        )}

                      {

                        entertainment[1] &&

                        entertainment[1].fileType === "Pdf" && (
                          <iframe
                            title="Pdf Viewer"
                            width="100%"
                            height="30vh"
                            src={

                              entertainment[1].photoUrl}
                            allowFullScreen
                            className='news_box_image_ees'
                          ></iframe>
                        )}

                      {

                        entertainment[1] === undefined && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={Dev}
                            className='card-img zoomable-image'
                            alt=""
                          />
                        )}
                    </div>
                    <div id='entertainment_text_box' style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{entertainment[1] ? entertainment[1].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(entertainment[1].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text-1 d-flex">{truncateText(entertainment[1] ? entertainment[1].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => navigateTo(entertainment[2].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={entertainment[2] ? entertainment[2].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "15vh" }} /> */}
                      {

                        entertainment[2] &&
                        entertainment[2].fileType === "Image" && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              entertainment[2].photoUrl}
                            alt=""
                            className='card-img zoomable-image'
                          />
                        )}

                      {

                        entertainment[2] &&

                        entertainment[2].fileType === "Video" && (
                          <video
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              entertainment[2].photoUrl}
                            controls
                            className='card-img'
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}

                      {

                        entertainment[2] &&

                        entertainment[2].fileType === "Link" && (
                          <iframe
                            title="Embedded Video"
                            width="100%"
                            height="15vh"
                            src={

                              entertainment[2].fileLink}
                            allowFullScreen
                            className='card-img'
                          ></iframe>
                        )}

                      {

                        entertainment[2] &&

                        entertainment[2].fileType === "Pdf" && (
                          <iframe
                            title="Pdf Viewer"
                            width="100%"
                            height="30vh"
                            src={

                              entertainment[2].photoUrl}
                            allowFullScreen
                            className='news_box_image_ees'
                          ></iframe>
                        )}

                      {

                        entertainment[2] === undefined && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={Dev}
                            className='card-img zoomable-image'
                            alt=""
                          />
                        )}
                    </div>
                    <div id='entertainment_text_box' style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{entertainment[2] ? entertainment[2].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(entertainment[2].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text-1 d-flex">{truncateText(entertainment[2] ? entertainment[2].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => navigateTo(entertainment[3].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={entertainment[3] ? entertainment[3].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "15vh" }} /> */}
                      {

                        entertainment[3] &&
                        entertainment[3].fileType === "Image" && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              entertainment[3].photoUrl}
                            alt=""
                            className='card-img zoomable-image'
                          />
                        )}

                      {

                        entertainment[3] &&

                        entertainment[3].fileType === "Video" && (
                          <video
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              entertainment[3].photoUrl}
                            controls
                            className='card-img'
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}

                      {

                        entertainment[3] &&

                        entertainment[3].fileType === "Link" && (
                          <iframe
                            title="Embedded Video"
                            width="100%"
                            height="15vh"
                            src={

                              entertainment[3].fileLink}
                            allowFullScreen
                            className='card-img'
                          ></iframe>
                        )}

                      {

                        entertainment[3] &&

                        entertainment[3].fileType === "Pdf" && (
                          <iframe
                            title="Pdf Viewer"
                            width="100%"
                            height="30vh"
                            src={

                              entertainment[3].photoUrl}
                            allowFullScreen
                            className='news_box_image_ees'
                          ></iframe>
                        )}

                      {

                        entertainment[3] === undefined && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={Dev}
                            className='card-img zoomable-image'
                            alt=""
                          />
                        )}
                    </div>
                    <div id='entertainment_text_box' style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{entertainment[3] ? entertainment[3].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(entertainment[3].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text-1 d-flex">{truncateText(entertainment[3] ? entertainment[3].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div id='education_box' style={{ width: "32%", marginLeft: "1.5%", height: "65vh", backgroundColor: "" }}>
            <div className="cat-box d-flex" style={{ width: "25%", justifyContent: "center", alignItems: "center", backgroundColor: "darkslateblue", color: "white", padding: "8px" }}>
              <h5 style={{ fontSize: "1vw" }}>Education</h5>
            </div>
            <div style={{ width: "100%", height: "0.3vh", backgroundColor: "darkslateblue" }}></div>
            <div id="education_news_box" className='mt-3'>
              <div onClick={() => navigateTo(education[0].postId)} className="cat-breaking-news" style={{ backgroundColor: "black" }}>
                {/* <img src={education[0] ? education[0].photoUrl : Dev} className='news_box_image_ees' style={{ width: "100%", height: "30vh", opacity: "0.8" }} alt="" /> */}
                {education[0] &&
                  education[0].fileType === "Image" && (
                    <img
                      style={{ width: "100%", height: "30vh", opacity: "0.8" }}
                      src={
                        education[0].photoUrl}
                      alt=""
                      className='zoomable-image'
                    />
                  )}

                {

                  education[0] &&

                  education[0].fileType === "Video" && (
                    <video
                      style={{ width: "100%", height: "30vh", opacity: "0.8" }}
                      src={

                        education[0].photoUrl}
                      controls
                    >
                      Your browser does not support the video tag.
                    </video>
                  )}

                {

                  education[0] &&

                  education[0].fileType === "Link" && (
                    <iframe
                      title="Embedded Video"
                      width="100%"
                      height="30vh"
                      src={

                        education[0].fileLink}
                      allowFullScreen
                    ></iframe>
                  )}

                {

                  education[0] &&

                  education[0].fileType === "Pdf" && (
                    <iframe
                      title="Pdf Viewer"
                      width="100%"
                      // height="30vh"
                      src={

                        education[0].photoUrl}
                      allowFullScreen
                    ></iframe>
                  )}

                {

                  education[0] === undefined && (
                    <img
                      style={{ width: "100%", height: "30vh", opacity: "0.8" }}
                      src={Dev}
                      alt=""
                      className='zoomable-image'
                    />
                  )}
                <div className="overlay-text-2">
                  <p>{education[0] ? education[0].dateTime : "31 January, 2024"}</p>
                  <Link onClick={() => navigateTo(education[0].postId)} style={{ textDecoration: "none", color: "white" }} className="active-hover"><h4>{truncateText(education[0] ? education[0].heading : "!!Your internet is not working properly!!", 7)}</h4></Link>
                </div>
              </div>
              <div id="box2" style={{ width: "100%", maxHeight: "55vh", marginLeft: "0%", marginTop: "2vh" }}>
                <div onClick={() => navigateTo(education[1].postId)} className="card" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={education[1] ? education[1].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "15vh" }} /> */}
                      {education[1] &&
                        education[1].fileType === "Image" && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              education[1].photoUrl}
                            alt=""
                            className='zoomable-image'
                          />
                        )}

                      {

                        education[1] &&

                        education[1].fileType === "Video" && (
                          <video
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              education[1].photoUrl}
                            controls
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}

                      {

                        education[1] &&

                        education[1].fileType === "Link" && (
                          <iframe
                            title="Embedded Video"
                            width="100%"
                            height="15vh"
                            src={

                              education[1].fileLink}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        education[1] &&

                        education[1].fileType === "Pdf" && (
                          <iframe
                            title="Pdf Viewer"
                            width="100%"
                            height="30vh"
                            src={

                              education[1].photoUrl}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        education[1] === undefined && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={Dev}
                            alt=""
                            className='zoomable-image'
                          />
                        )}
                    </div>
                    <div id='education_text_box' style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{education[1] ? education[1].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(education[1].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text-1 d-flex">{truncateText(education[1] ? education[1].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => navigateTo(education[2].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={education[2] ? education[2].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "15vh" }} /> */}
                      {education[2] &&
                        education[2].fileType === "Image" && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              education[2].photoUrl}
                            alt=""
                            className='zoomable-image'
                          />
                        )}

                      {

                        education[2] &&

                        education[2].fileType === "Video" && (
                          <video
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              education[2].photoUrl}
                            controls
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}

                      {

                        education[2] &&

                        education[2].fileType === "Link" && (
                          <iframe
                            title="Embedded Video"
                            width="100%"
                            height="15vh"
                            src={

                              education[2].fileLink}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        education[2] &&

                        education[2].fileType === "Pdf" && (
                          <iframe
                            title="Pdf Viewer"
                            width="100%"
                            height="30vh"
                            src={

                              education[2].photoUrl}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        education[2] === undefined && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={Dev}
                            alt=""
                            className='zoomable-image'
                          />
                        )}
                    </div>
                    <div id='education_text_box' style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{education[2] ? education[2].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(education[2].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text-1 d-flex">{truncateText(education[2] ? education[2].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => navigateTo(education[3].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={education[3] ? education[3].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "15vh" }} /> */}
                      {education[3] &&
                        education[3].fileType === "Image" && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              education[3].photoUrl}
                            alt=""
                            className='zoomable-image'
                          />
                        )}

                      {

                        education[3] &&

                        education[3].fileType === "Video" && (
                          <video
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              education[3].photoUrl}
                            controls
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}

                      {

                        education[3] &&

                        education[3].fileType === "Link" && (
                          <iframe
                            title="Embedded Video"
                            width="100%"
                            height="15vh"
                            src={

                              education[3].fileLink}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        education[3] &&

                        education[3].fileType === "Pdf" && (
                          <iframe
                            title="Pdf Viewer"
                            width="100%"
                            height="30vh"
                            src={

                              education[3].photoUrl}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        education[3] === undefined && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={Dev}
                            alt=""
                            className='zoomable-image'
                          />
                        )}
                    </div>
                    <div style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div id='education_text_box' className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{education[3] ? education[3].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(education[3].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text-1 d-flex">{truncateText(education[3] ? education[3].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id='sports_box' style={{ width: "32.5%", marginLeft: "1.5%", height: "65vh", backgroundColor: "" }}>
            <div className="cat-box d-flex" style={{ width: "25%", justifyContent: "center", alignItems: "center", backgroundColor: "burlywood", color: "white", padding: "8px" }}>
              <h5 style={{ fontSize: "1vw" }}>BSP</h5>
            </div>
            <div style={{ width: "100%", height: "0.3vh", backgroundColor: "burlywood" }}></div>
            <div id="sports_news_box" className='mt-3'>
              <div onClick={() => navigateTo(sports[0].postId)} className="cat-breaking-news" style={{ backgroundColor: "black" }}>
                {/* <img src={sports[0] ? sports[0].photoUrl : Dev} style={{ width: "100%", height: "30vh", opacity: "0.8" }} alt="" /> */}
                {sports[0] &&
                  sports[0].fileType === "Image" && (
                    <img
                      style={{ width: "100%", height: "30vh", opacity: "0.8" }}
                      src={

                        sports[0].photoUrl}
                      alt=""
                      className='zoomable-image'
                    />
                  )}

                {

                  sports[0] &&

                  sports[0].fileType === "Video" && (
                    <video
                      style={{ width: "100%", height: "30vh", opacity: "0.8" }}
                      src={

                        sports[0].photoUrl}
                      controls
                    >
                      Your browser does not support the video tag.
                    </video>
                  )}

                {

                  sports[0] &&

                  sports[0].fileType === "Link" && (
                    <iframe
                      title="Embedded Video"
                      width="100%"
                      // minHeight="30vh"
                      src={

                        sports[0].fileLink}
                      allowFullScreen
                    ></iframe>
                  )}

                {

                  sports[0] &&

                  sports[0].fileType === "Pdf" && (
                    <iframe
                      title="Pdf Viewer"
                      width="100%"
                      height="30vh"
                      src={

                        sports[0].photoUrl}
                      allowFullScreen
                    ></iframe>
                  )}

                {

                  sports[0] === undefined && (
                    <img
                      style={{ width: "100%", height: "30vh", opacity: "0.8" }}
                      src={Dev}
                      alt=""
                      className='zoomable-image'
                    />
                  )}
                <div className="overlay-text-2">
                  <p>{sports[0] ? sports[0].dateTime : "31 January, 2024"}</p>
                  <Link onClick={() => navigateTo(sports[0].postId)} style={{ textDecoration: "none", color: "white" }} className="active-hover"><h4>{truncateText(sports[0] ? sports[0].heading : "!!Your internet is not working properly!!", 7)}</h4></Link>
                </div>
              </div>
              <div id="box2" style={{ width: "100%", maxHeight: "55vh", marginLeft: "0%", marginTop: "2vh" }}>
                <div onClick={() => navigateTo(sports[1].postId)} className="card" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={sports[1] ? sports[1].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "15vh" }} /> */}
                      {sports[1] &&
                        sports[1].fileType === "Image" && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              sports[1].photoUrl}
                            alt=""
                            className='zoomable-image'
                          />
                        )}

                      {

                        sports[1] &&

                        sports[1].fileType === "Video" && (
                          <video
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              sports[1].photoUrl}
                            controls
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}

                      {

                        sports[1] &&

                        sports[1].fileType === "Link" && (
                          <iframe
                            title="Embedded Video"
                            width="100%"
                            height="15vh"
                            src={

                              sports[1].fileLink}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        sports[1] &&

                        sports[1].fileType === "Pdf" && (
                          <iframe
                            title="Pdf Viewer"
                            width="100%"
                            height="30vh"
                            src={

                              sports[1].photoUrl}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        sports[1] === undefined && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={Dev}
                            alt=""
                            className='zoomable-image'
                          />
                        )}
                    </div>
                    <div id='sports_text_box' style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{sports[1] ? sports[1].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(sports[1].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text-1 d-flex">{truncateText(sports[1] ? sports[1].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => navigateTo(sports[2].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={sports[2] ? sports[2].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "15vh" }} /> */}
                      {sports[2] &&
                        sports[2].fileType === "Image" && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              sports[2].photoUrl}
                            alt=""
                            className='zoomable-image'
                          />
                        )}

                      {

                        sports[2] &&

                        sports[2].fileType === "Video" && (
                          <video
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              sports[2].photoUrl}
                            controls
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}

                      {

                        sports[2] &&

                        sports[2].fileType === "Link" && (
                          <iframe
                            title="Embedded Video"
                            width="100%"
                            height="15vh"
                            src={

                              sports[2].fileLink}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        sports[2] &&

                        sports[2].fileType === "Pdf" && (
                          <iframe
                            title="Pdf Viewer"
                            width="100%"
                            height="30vh"
                            src={

                              sports[2].photoUrl}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        sports[2] === undefined && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={Dev}
                            alt=""
                            className='zoomable-image'
                          />
                        )}
                    </div>
                    <div id='sports_text_box' style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{sports[2] ? sports[2].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(sports[2].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text-1 d-flex">{truncateText(sports[2] ? sports[2].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => navigateTo(sports[3].postId)} className="card mt-4" style={{ height: "31%" }}>
                  <div className="row">
                    <div style={{ height: "100%", width: "30%" }}>
                      {/* <img src={sports[3] ? sports[3].photoUrl : Dev} class="card-img" alt="Image Alt Text" style={{ height: "15vh" }} /> */}
                      {sports[3] &&
                        sports[3].fileType === "Image" && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              sports[3].photoUrl}
                            alt=""
                            className='zoomable-image'
                          />
                        )}

                      {

                        sports[3] &&

                        sports[3].fileType === "Video" && (
                          <video
                            style={{ width: "100%", height: "15vh" }}
                            src={

                              sports[3].photoUrl}
                            controls
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}

                      {

                        sports[3] &&

                        sports[3].fileType === "Link" && (
                          <iframe
                            title="Embedded Video"
                            width="100%"
                            height="15vh"
                            src={

                              sports[3].fileLink}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        sports[3] &&

                        sports[3].fileType === "Pdf" && (
                          <iframe
                            title="Pdf Viewer"
                            width="100%"
                            height="30vh"
                            src={

                              sports[3].photoUrl}
                            allowFullScreen
                          ></iframe>
                        )}

                      {

                        sports[3] === undefined && (
                          <img
                            style={{ width: "100%", height: "15vh" }}
                            src={Dev}
                            alt=""
                            className='zoomable-image'
                          />
                        )}
                    </div>
                    <div id='sports_text_box' style={{ height: "100%", width: "70%", paddingLeft: "0px", marginLeft: "0px" }}>
                      <div className="card-body" style={{ height: "100%", width: "100%", paddingLeft: "0px", marginLeft: "0px" }}>
                        <p className="card-title text-secondary">{sports[3] ? sports[3].dateTime : "31 January, 2024"}</p>
                        <Link onClick={() => navigateTo(sports[3].postId)} style={{ textDecoration: "none" }}><p style={{ fontWeight: "400", fontSize: "1.01vw" }} className="card-text-1 d-flex">{truncateText(sports[3] ? sports[3].heading : "!!Your internet is not working properly!!", 12)}</p></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="another_box_3" style={{ marginTop: "19%" }}>
          <div className="cat-box d-flex" style={{ width: "15%", justifyContent: "center", alignItems: "center", backgroundColor: "palevioletred", color: "white", padding: "8px", marginTop: "14vh" }}>
            <h5 style={{ fontSize: "1vw" }}>National</h5>
          </div>
          <div style={{ width: "100%", height: "0.3vh", backgroundColor: "palevioletred" }}></div>
          <div className="internation_division_box d-flex">
            <div className="news_box" style={{ width: "69%" }}>
              {international.map((card, index) => (
                index<5 && (<Col key={card.id} md={12} className="mb-12 mt-5">
                  <Card onClick={() => navigateTo(international[index].postId)} style={{ height: "20vh" }} className='d-flex flex-row card' id='inter_card'>
                    {international[index]?.fileType === "Image" && (
                      <Card.Img
                        variant="top"
                        src={international[index].photoUrl || card.imageUrl}
                        style={{ width: "34%" }}
                        className='zoomable-image'
                      />
                    )}

                    {international[index] === undefined && (
                      <Card.Img
                        variant="top"
                        src={Dev}
                        style={{ width: "34%" }}
                        className='zoomable-image'
                      />
                    )}

                    {international[index]?.fileType === "Video" && (
                      <video
                        src={international[index].photoUrl || Dev}
                        style={{ width: "34%" }}
                        controls
                      >
                        Your browser does not support the video tag.
                      </video>
                    )}

                    {international[index]?.fileType === "Link" && (
                      <iframe
                        title="Embedded Video"
                        width="34%"
                        height="100%"
                        src={international[index].fileLink || Dev}
                        allowFullScreen
                      ></iframe>
                    )}

                    {international[index]?.fileType === "Pdf" && (
                      <iframe
                        title="Pdf Viewer"
                        width="34%"
                        height="100%"
                        src={international[index].photoUrl}
                        allowFullScreen
                      ></iframe>
                    )}

                    <Card.Body style={{ width: "60%", padding: "1rem", paddingLeft: "5%" }}>
                      <Card.Text className='card-text' style={{ fontSize: "0.8vw", color: "grey" }}>{international[index] ? international[index].dateTime : "January 15, 2024"}</Card.Text>
                      <Card.Text onClick={() => navigateTo(international[index].postId)} className='card-text' style={{ fontSize: "0.8vw" }}><Link style={{ textDecoration: "none" }}>{truncateText(international[index] ? international[index].heading : card.body, 15)}</Link></Card.Text>
                    </Card.Body>
                  </Card>
                </Col>)
              ))}
            </div>
            <div id="divided_box_2" style={{ width: "30%", backgroundColor: "", marginLeft: "1%" }}>
              <img src={ads[5] ? ads[5] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "13vh" }} alt="" />
              {international.length > 1 && <img src={ads[6] ? ads[6] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "9vh" }} alt="" />}
              {international.length > 4 && <img src={ads[7] ? ads[7] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "13vh" }} alt="" />}
              {/* <img src={SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "13vh" }} alt="" /> */}
              {/* <img src={SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "9vh" }} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
