import React from 'react';

const PDFViewer = ({ url }) => {
    const handleDownload = () => {
        window.open(url, '_blank');
    };

    return (
        <div style={{ width: "100%" }}>
            <iframe
                title="PDF Viewer"
                width="100%"
                height="700px"
                src={url}
                allowFullScreen
            ></iframe>
            <div style={{ marginTop: "15px" }}>
                <button className='btn btn-success' onClick={handleDownload}>Download</button>
                <a className='btn btn-primary ml-3' href={url} target="_blank" rel="noopener noreferrer">Open in New Tab</a>
            </div>
        </div>
    );
};

export default PDFViewer;