import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const [acknowledgement, setAcknowledgement] = useState([]);
  const authToken = localStorage.getItem('newsapp_auth_token');
  const showAdminLinks = authToken !== null;
  const navigation = useNavigate("");

  const fetchAcknowledgement = () => {
    fetch(`https://d19rkiagm94rwl.cloudfront.net/api/ads/user/ack`)
      .then(response => {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        return response.json();
      })
      .then(data => {
        setAcknowledgement(data);
      })
      .catch(error => console.error('Error:', error));
  }

  const deleteAcknowledgement = (id) => {
    if(!showAdminLinks)return;
    fetch(`https://d19rkiagm94rwl.cloudfront.net/api/ads/user/ack/${"ack"+id+1}`,{
      method:"DELETE"
    })
      .then(response => {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        navigation("/")
      })
      .catch(error => console.error('Error:', error));
  }

  useEffect(()=>{
    fetchAcknowledgement();
  },[])

  const imageUrls = acknowledgement.map(item => item.adsUrl);
  console.log(imageUrls)

  return (
    <Slider {...settings}>
      {imageUrls.map((imageUrl, index) => (
        <div key={index}>
          <img 
            onClick={() => deleteAcknowledgement(index)} 
            src={imageUrl} 
            style={{ width: '100%', height: '40vh', objectFit: 'cover' }} 
            alt={`Image ${index}`} 
          />
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
