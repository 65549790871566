import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';
import '../CSS/AddAd.css';
import anotherLogo from '../assets/logo-removebg-preview.png';

const AddAdvertisementForm = () => {
  const [preference, setPreference] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();

  const preferences = ["logo","ack1","ack2","ack3", "ack4","ack5","1", "2", "3", "4", "5", "6", "7"];

  const toastData = {
    position: 'bottom-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleAddAdvertisement = async () => {
    // Basic validation
    if (!preference || !file) {
      setError('All fields are required');
      return;
    }

    // Get the authorization token from localStorage
    const authToken = localStorage.getItem('newsapp_auth_token');

    // Basic validation for the token
    if (!authToken) {
      setError('Authorization token not found. Please log in.');
      return;
    }

    // Prepare the FormData object for the file
    const formData = new FormData();
    formData.append('file', file);

    try {
      setLoading(true);
      const response = await fetch(`https://d19rkiagm94rwl.cloudfront.net/api/ads/post/${preference}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Reset form and clear error
      setPreference('');
      setFile(null);
      setError('');
      setFile(null);
      toast.success("Successfully added", toastData)

    //  console.log('Advertisement added successfully');
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false); // Set loading to false regardless of success or error
    }
  };

  return (
    <div className="d-flex flex-column align-items-center" style={{ minHeight: '75vh', backgroundColor: '#343a40', color: 'white' }}>
      <div id='addAd_page_heading' className="mb-4" style={{ marginTop: "10vh" }}>
      <Link to="/"><img style={{width:"350px", height:"200px", marginTop:"-9px"}} src={anotherLogo} alt="Aap tak Chhattisgarh logo" /></Link>
      </div>
      <Container id='add_ad_form_div'>
        <Row className="justify-content-center mb-5">
          <Col md={8} className="p-4 shadow rounded bg-dark mb-5">
            <h2 className="mb-4 text-center">Add Advertisement</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
              <Form.Group controlId="formPreference">
                <Form.Label>Preference</Form.Label>
                <Form.Control
                  as="select"
                  value={preference}
                  onChange={(e) => setPreference(e.target.value)}
                >
                  <option value="" disabled>Select Preference</option>
                  {preferences.map((pref, index) => (
                    <option key={index} value={pref}>{pref}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formFile" className='mt-5'>
                <Form.Label>Upload Image</Form.Label>
                <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
              </Form.Group>

              <div className="d-flex justify-content-center align-items-center mt-5">
              <Button variant="danger" className='btn-lg' type="button" block onClick={() => navigation("/")}>
                  Home
                </Button>
                <Button variant="primary" className='btn-lg ml-4' type="button" block onClick={handleAddAdvertisement}>
                {loading ? 'Posting...' : 'Add Advertisement'}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default AddAdvertisementForm;
