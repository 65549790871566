
import './App.css';
import MainPage from './Pages/MainPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import News from './Pages/News';
import NewsPost from './Pages/NewsPost';
import Login from './Pages/Login';
import AddNewsForm from './Pages/AddNewsForm';
import LoadingPage from './Components/LoadingPage';
import AddAdvertisementForm from './Pages/AddAdvertisementForm';
import React, { useEffect, useState } from 'react';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    const fetchData = async () => {
      // Your asynchronous operations here

      // Simulate a delay (you can replace this with actual async logic)
      await new Promise(resolve => setTimeout(resolve, 3000));

      // Once the asynchronous operations are done, set isLoading to false
      setIsLoading(false);
    };

    fetchData();
  }, []);
  return (
    <Router>
    {isLoading ? (
      <LoadingPage />
    ) : (
      <React.Fragment>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/cat" element={<News />} />
          <Route path='/news/:postId' element={<NewsPost />} />
          <Route path="/login" element={<Login />} />
          <Route path='/addNews' element={<AddNewsForm />} />
          <Route path='/addAd' element={<AddAdvertisementForm />} />
        </Routes>
        <Footer />
      </React.Fragment>
    )}
  </Router>
  );
}

export default App;
