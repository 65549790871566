import React from 'react'
import '../CSS/Footer.css'
import anotherLogo from '../assets/logo-removebg-preview.png';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div style={{ backgroundColor: "black", marginTop: "0vh" }} id='footer'>
            <div className='d-flex flex-column' style={{ justifyContent: "center", alignItems: "center", paddingTop: "5vh" }}>
                <Link className='d-flex flex-column align-items-center mb-4' style={{textDecoration:"none"}} to="/">
                    <img style={{ width: "170px", height: "170px", marginTop: "-9px" }} src={anotherLogo} alt="Aap tak Chhattisgarh logo" />
                    <h1 className='' style={{ fontWeight: "bold", fontSize: "1.2rem",marginLeft:"-1.5rem", marginTop: "-7.8vh", color: "#fff", textDecoration:"none" }}>सच आप तक</h1>
                </Link>
            </div>
            <div id="social_media_div" className='d-flex' style={{ justifyContent: "center", alignItems: "center" }}>
                <Link style={{ textDecoration: "none" }} to={"https://www.facebook.com/profile.php?id=61554438800824&sfnsn=wiwspwa&mibextid=RUbZ1f"}>
                    <div className="social_media bg-secondary d-flex" style={{ width: "50px", height: "50px", justifyContent: "center", alignItems: "center" }}>
                        <i className="fa-brands text-dark fa-facebook-f fa-2x"></i>
                    </div>
                </Link>
                <div className="social_media ml-4 bg-secondary d-flex" style={{ width: "50px", height: "50px", justifyContent: "center", alignItems: "center" }}>
                    <i className="fa-brands fa-twitter fa-2x"></i>
                </div>
                <Link style={{ textDecoration: "none" }} to={"https://www.youtube.com/@newsaaptakchhattisgarh"}>
                    <div className="social_media ml-4 bg-secondary d-flex" style={{ width: "50px", height: "50px", justifyContent: "center", alignItems: "center" }}>
                        <i className="fa-brands text-dark fa-youtube fa-2x"></i>
                    </div></Link>
                <Link style={{ textDecoration: "none" }} to={"https://www.instagram.com/newsaaptakchhattisgarh?igsh=ajFkNGxtdHR3a3Vs"}>
                    <div className="social_media ml-4 bg-secondary d-flex" style={{ width: "50px", height: "50px", justifyContent: "center", alignItems: "center" }}>
                        <i className="fa-brands fa-instagram text-dark fa-2x"></i>
                    </div></Link>
            </div>
            <div className="d-flex" id='footer_content' style={{ justifyContent: "center", alignItems: "center" }}>
                <p className='text-white mt-4 mb-5'>&copy; Copyright Aap Tak Chhattisgarh 2022 | All Rights Reserved</p>
            </div>
        </div>
    )
}
