import React, { useEffect, useState } from 'react'
import Header from '../Components/Header';
import HiddenNavbar from '../Components/HiddenNavbar';
import SS from '../assets/Screenshot from 2024-01-08 01-34-34.png';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dev from '../assets/breaking_news.jpg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../CSS/News.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Carousel from '../Components/Carousel';

export default function News(props) {

    const [showHiddenNavbar, setShowHiddenNavbar] = useState(false);
    const location = useLocation();
    const category = new URLSearchParams(location.search).get('category');
    const [cat, setCat] = useState(category)
    const [formattedDate, setFormattedDate] = useState("");



    const truncateText = (text, maxWords) => {
        if (text) {
            const words = text.split(' ');

            if (words.length <= maxWords) {
                return text;
            }
            const truncatedText = words.slice(0, maxWords).join(' ');
            return `${truncatedText}...`;
        }

        return text;
    };


    let yourDataArray = [
        {
            postId: 1,
            photoUrl: Dev,
            heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Latest-News'
        },
        {
            postId: 2,
            photoUrl: Dev,
            heading: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Latest-News'
        },
        {
            postId: 3,
            photoUrl: Dev,
            heading: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Latest-News'

        },
        {
            postId: 4,
            photoUrl: Dev,
            heading: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Latest-News'

        },
        {
            postId: 5,
            photoUrl: Dev,
            heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Latest-News'

        },
        {
            postId: 6,
            photoUrl: Dev,
            heading: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Politics'
        },
        {
            postId: 7,
            photoUrl: Dev,
            heading: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Politics'
        },
        {
            postId: 8,
            photoUrl: Dev,
            heading: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Politics'
        },
        {
            postId: 9,
            photoUrl: Dev,
            heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Politics'
        },
        {
            postId: 10,
            photoUrl: Dev,
            heading: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Politics'
        },
        {
            postId: 11,
            photoUrl: Dev,
            heading: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Nagar-Nigam'
        },
        {
            postId: 12,
            photoUrl: Dev,
            heading: 'Duis ****************** irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Nagar-Nigam'
        },
        {
            postId: 13,
            photoUrl: Dev,
            heading: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Nagar-Nigam'
        },
        {
            postId: 14,
            photoUrl: Dev,
            heading: 'Duis ****************** irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Nagar-Nigam'
        },
        {
            postId: 15,
            photoUrl: Dev,
            heading: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Nagar-Nigam'
        },
        {
            postId: 16,
            photoUrl: Dev,
            heading: 'Duis ****************** irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Covid'
        },
        {
            postId: 17,
            photoUrl: Dev,
            heading: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Covid'
        },
        {
            postId: 18,
            photoUrl: Dev,
            heading: 'Duis ****************** irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Covid'
        },
        {
            postId: 19,
            photoUrl: Dev,
            heading: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Covid'
        },
        {
            postId: 20,
            photoUrl: Dev,
            heading: 'Duis ****************** irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Covid'
        },
        {
            postId: 21,
            photoUrl: Dev,
            heading: 'Duis ****************** irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            dateTime: formattedDate,
            content: 'This configuration allows cross-origin requests for all endpoints under /api and specifies the allowed methods. Adjust the paths and origins based on your specific requirements.After making these changes, your React app should be able to make requests to the Spring Boot backend without encountering CORS issues.',
            category: 'Covid'
        },
        // Add more objects as needed
    ];

    const [latestNews, setLatestNews] = useState([]);
    const fetchCategoryWiseNews = () => {
        fetch(category != "Latest-News" ? `https://d19rkiagm94rwl.cloudfront.net/api/photos/news/get/${category}` : 'https://d19rkiagm94rwl.cloudfront.net/api/photos/news/latest').then(response => {
            if (!response.ok)
                throw new Error(`HTTP error! Status: ${response.status}`);
            return response.json();
        })
            .then(data => {
              //  console.log(data)
                setLatestNews(data);
            })
            .catch(error => console.error('Error:', error));
    }

    const [ads, setAds] = useState([]);
    const fetchAds = () => {
        fetch("https://d19rkiagm94rwl.cloudfront.net/api/ads/user/all")
            .then(response => {
                if (!response.ok)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                return response.json();
            })
            .then(data => {
                const adsObject = {};
                data.forEach(({ adsId, adsUrl }) => {
                    adsObject[adsId] = adsUrl;
                });
                setAds(adsObject);
            })
            .catch(error => console.error('Error:', error));
    };

    const [startIndex, setStartIndex] = useState(0); // Track the start index of the displayed cards
    const cardsPerPage = 10; // Number of cards to display per page
    yourDataArray = latestNews ? latestNews : yourDataArray;
    const totalCards = yourDataArray.length; // Replace 'yourDataArray' with your actual array of data
    const currentCards = yourDataArray.slice(startIndex, startIndex + cardsPerPage);
    const toastData = {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const navigation = useNavigate();

    const deletePost = (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this post?');
        if (isConfirmed) {
          //  console.log('Post deleted!');
            const authToken = localStorage.getItem('newsapp_auth_token');
            fetch(`https://d19rkiagm94rwl.cloudfront.net/api/photos/${id}`, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                }
            }).then((response) => {
              //  console.log(response);
                toast.success("Post deleted", toastData);
                navigation("/");
            })
        } else {
          //  console.log('Deletion canceled.');
            return;
        }
    }

    const navigateTo = (id) => {
        if (id) navigation(`/news/${id}`);
    }

    useEffect(() => {
        // console.log(category)
        fetchCategoryWiseNews()
        window.scrollTo(0, 0);
        setStartIndex(0);
        fetchAds();
        const currentDate = new Date();
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            // Adjust the scroll threshold as needed (20vh in this case)
            setShowHiddenNavbar(scrollPosition > 0.5 * window.innerHeight);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location.search, cat]);

    return (
        <div>
            <Header />
            <HiddenNavbar showFixed={showHiddenNavbar} />
            <div className="container-fluid main-div mt-3" style={{ width: "80%", marginBottom: "3vh" }}>
                <div className="d-flex" id='news_page'>
                    <div id="news_section" style={{ width: "69%" }}>
                        {currentCards.slice(0, 11).map((card, index) => (
                            <Col key={card.postId} md={12} className="mb-12 mt-5">
                                <Card style={{ minHeight: "26vh", height: "auto", marginTop: "7vh" }} className='d-flex flex-row card'>
                                    {card.fileType === "Image" && (
                                        <Card.Img
                                            onClick={() => navigateTo(latestNews[index].postId)}
                                            id="news_page_card_img"
                                            className='zoomable-image'
                                            variant="top"
                                            src={card.photoUrl ? card.photoUrl : Dev}
                                            style={{ width: "34%", height: "350px" }}
                                        />
                                    )}

                                    {card.fileType === "Video" && (
                                        <video
                                            onClick={() => navigateTo(latestNews[index].postId)}
                                            id="news_page_card_img"
                                            style={{ width: "34%", height: "350px" }}
                                            controls
                                        >
                                            <source src={card.photoUrl} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    )}

                                    {card.fileType === "Link" && (
                                        <iframe
                                            title="Embedded Video"
                                            id="news_page_card_img"
                                            width="100%"
                                            height="auto"
                                            src={card.fileLink}
                                            allowFullScreen
                                            style={{ width: "34%", height: "350px" }}
                                        ></iframe>
                                    )}

                                    {card.fileType === "Pdf" && (
                                        <iframe
                                            title="Pdf Viewer"
                                            id="news_page_card_img"
                                            width="100%"
                                            height="auto"
                                            src={card.photoUrl}
                                            allowFullScreen
                                            style={{ width: "34%", height: "350px" }}
                                        ></iframe>
                                    )}
                                    <Card.Body className='card_body_news_page' style={{ width: "60%", padding: "1rem", paddingLeft: "5%" }}>
                                        <Card.Text id='news_page_date' style={{ fontSize: "0.9vw" }}>{card.dateTime}</Card.Text>
                                        <Card.Text onClick={() => navigateTo(latestNews[index].postId)} id='news_page_heading' style={{ fontSize: "0.9vw" }}><Link style={{ textDecoration: "none" }}>{truncateText(card.heading, 45)}</Link></Card.Text>
                                        <Card.Text onClick={() => navigateTo(latestNews[index].postId)} id='news_page_content' style={{ fontSize: "0.8vw", paddingTop: "3px" }}>{truncateText(card.c1, 45)}</Card.Text>
                                        {localStorage.getItem("newsapp_auth_token") && <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                                            <button onClick={() => navigation("/addNews", { state: card.postId })} className='btn btn-sm btn-warning ml-1'>Update</button>
                                            <button onClick={() => deletePost(card.postId)} className='btn btn-sm btn-danger ml-1'>Delete</button>
                                        </div>}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                        <div className="d-flex mt-5" style={{ justifyContent: "center" }}>
                            <button onClick={() => setStartIndex(startIndex - 10)} disabled={startIndex == 0 ? true : false} className='text-dark ml-3' style={{ backgroundColor: startIndex == 0 ? "white" : 'lightgray' }}>&lt;&lt;&lt;</button>
                            <div className='ml-3 d-flex' style={{ justifyContent: "center", alignItems: "center", width: "50px", height: "50px", backgroundColor: "teal" }}>
                                <h2 className='text-white' style={{ fontFamily: "fantasy" }}>{(startIndex / 10) + 1}</h2>
                            </div>
                            <button onClick={() => setStartIndex(startIndex + 10)} disabled={startIndex + 10 >= yourDataArray.length ? true : false} className='text-dark ml-3' style={{ backgroundColor: startIndex + 10 >= yourDataArray.length ? "white" : 'lightgray' }}>&gt;&gt;&gt;</button>
                        </div>
                    </div>
                    <div id="divided_box_23" style={{ width: "30%", marginTop: "10vh", marginLeft: "1%" }}>
                        <div style={{ marginLeft: "10%" }}>
                            <Carousel />
                        </div>
                        <img className='news_page_ads' src={ads[2] ? ads[2] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "14vh" }} alt="" />
                        {currentCards.length % 10 > 2 || currentCards.length === 10 ? <img className='news_page_ads' src={ads[3] ? ads[3] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "17vh" }} alt="" /> : null}
                        {currentCards.length % 10 >= 6 || currentCards.length === 10 ? <img className='news_page_ads' src={ads[4] ? ads[4] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "17vh" }} alt="" /> : null}
                        {currentCards.length % 10 >= 8 || currentCards.length === 10 ? <img className='news_page_ads' src={ads[5] ? ads[5] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "17vh" }} alt="" /> : null}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
