import React from 'react';

const DynamicParagraph = ({ paragraphWithLink }) => {
  const processParagraph = (paragraph) => {
    // Regular expression to find URLs in the paragraph
    if (paragraph) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      // Split the paragraph into parts with and without links
      const parts = paragraph.split(urlRegex);

      // Process each part and insert anchor tags for links
      const processedParagraph = parts.map((part, index) => (
        urlRegex.test(part) ? (
          <a href={part} key={index} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        ) : (
          part
        )
      ));

      return processedParagraph;
    }
  };

  return (
    <p style={{ fontSize: "1.2vw", justifyContent: "center", textAlign: "justify", inlineSize: "95%", whiteSpace: "pre-wrap" }}>
      {processParagraph(paragraphWithLink)}
    </p>
  );
};

export default DynamicParagraph;
