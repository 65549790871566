import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../CSS/AddNews.css';
import anotherLogo from '../assets/logo-removebg-preview.png';
import defaultImage from '../assets/breaking_news.jpg';

const AddNewsForm = () => {
  const [newsHeadline, setNewsHeadline] = useState('');
  const [content, setContent] = useState('');
  const [content2, setContent2] = useState('');
  const [content3, setContent3] = useState('');
  const [content4, setContent4] = useState('');
  const [content5, setContent5] = useState('');
  const [content6, setContent6] = useState('');
  const [latestNewsChecked, setLatestNewsChecked] = useState(false);
  const [category, setCategory] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const [fileType, setFileType] = useState("Image");
  const [fileLink, setFileLink] = useState(null);

  const categories = ["Bhilai-Durg", "CG", "BSP", "Politics", "Crime", "National", "International", "Sports", "Education", "Employment", "Bollywood", "Tourism", "Astrology"];

  const toastData = {
    position: 'bottom-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const location = useLocation();
  const { state: postId } = location;
  const authToken = localStorage.getItem('newsapp_auth_token');
  // console.log(authToken)

  useEffect(() => {
    const currentDate = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formatted = new Intl.DateTimeFormat('en-US', options).format(currentDate);
    setFormattedDate(formatted);

    if (postId) {
      fetch(`https://d19rkiagm94rwl.cloudfront.net/api/photos/news/${postId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      }).then((response) => {
        response.json().then((result) => {
          setNewsHeadline(result.heading);
          setContent(result.c1);
          setContent2(result.c2)
          setContent3(result.c3)
          setContent4(result.c4)
          setContent5(result.c5)
          setContent6(result.c6)
          setCategory(result.category);
          setFileLink(result.fileLink);
          setFileType(result.fileType);
        })
      })
    }

  }, [])

  const handleAddNews = async () => {
    // Basic validation
    if (!newsHeadline || !content || !category) {
      setError('All fields are required');
      return;
    }

    // Basic validation for the token
    if (!authToken) {
      setError('Authorization token not found. Please log in.');
      return;
    }

    // Prepare the FormData object for the file
    const formData = new FormData();
    const data = {
      heading: newsHeadline,
      dateTime: formattedDate,
      c1: content,
      c2: content2,
      c3: content3,
      c4: content4,
      c5: content5,
      c6: content6,
      latest:latestNewsChecked,
      category: category,
      fileType: fileType,
      fileLink: fileLink
    }

    const upDatedData = {
      postId: postId,
      heading: newsHeadline,
      dateTime: formattedDate,
      c1: content,
      c2: content2,
      c3: content3,
      c4: content4,
      c5: content5,
      c6: content6,
      latest:latestNewsChecked,
      category: category,
      fileType: fileType,
      fileLink: fileLink
    }
    formData.append('newsDTO', JSON.stringify(postId ? upDatedData : data));
    if (file) {
      formData.append('file', file);
    } else if (fileType != "Link" && file == null && !postId) {
      toast.error("Please add photo,video,pdf or youtube link", toastData);
      return false;
    }

    try {
      setLoading(true); // Set loading to true when making the API call

      const response = await fetch('https://d19rkiagm94rwl.cloudfront.net/api/photos/addPost', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setNewsHeadline('');
      setContent('');
      setContent2('');
      setContent3('');
      setContent4('');
      setContent5('');
      setContent6('');
      setCategory('');
      setFile(null);
      setError('');
      toast.success(postId ? 'News updated successfully' : 'News added successfully', toastData);
    //  console.log('News added successfully');
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false); // Set loading to false regardless of success or error
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '100vh', backgroundColor: '#343a40', color: 'white' }}>
      <div id='addNews_page_heading' className="mb-4">
        <Link to="/"><img style={{ width: "350px", height: "200px", marginTop: "-9px" }} src={anotherLogo} alt="Aap tak Chhattisgarh logo" /></Link>
      </div>
      <Container className='mb-5' id='add_news_form_div'>
        <Row className="justify-content-center">
          <Col md={8} className="p-4 shadow rounded bg-dark mb-5">
            <h2 className="mb-4 text-center">{postId ? "Update News" : "Add News"}</h2>

            {/* Display error message */}
            {error && <Alert variant="danger">{error}</Alert>}

            <Form.Group controlId="formCategory" className='mt-5'>
              <Form.Control
                as="select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="" disabled>Select Category</option>
                {categories.map((cat, index) => (
                  <option key={index} value={cat}>{cat}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form>
              <Form.Group controlId="formNewsHeadline">
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter News Headline"
                  value={newsHeadline}
                  className='mt-4'
                  onChange={(e) => setNewsHeadline(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formContent" className='mt-4'>
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Enter Content - 1"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formContent" className='mt-4'>
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Enter Content - 2"
                  value={content2}
                  onChange={(e) => setContent2(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formContent" className='mt-4'>
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Enter Content - 3"
                  value={content3}
                  onChange={(e) => setContent3(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formContent" className='mt-4'>
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Enter Content - 4"
                  value={content4}
                  onChange={(e) => setContent4(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formContent" className='mt-4'>
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Enter Content - 5"
                  value={content5}
                  onChange={(e) => setContent5(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formContent" className='mt-4'>
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Enter Content - 6"
                  value={content6}
                  onChange={(e) => setContent6(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formLatestNews">
                <Form.Check
                  type="checkbox"
                  label="Latest-News"
                  className='mt-4'
                  checked={latestNewsChecked}
                  onChange={(e) => setLatestNewsChecked(e.target.checked)}
                />
              </Form.Group>

              <Form.Group controlId="uploadCategory" className='mt-4'>
                <Form.Label>Select File Type</Form.Label>
                <Form.Control
                  as="select"
                  value={fileType}
                  required
                  onChange={(e) => setFileType(e.target.value)}
                >
                  <option value="" disabled>Select File Type</option>
                  <option value="Image">Image</option>
                  <option value="Video">Video</option>
                  <option value="Link">Link</option>
                  <option value="Pdf">Pdf</option>
                </Form.Control>
              </Form.Group>

              {fileType == "Link" ?
                <Form.Group controlId="formLink" className='mt-4'>
                  <Form.Label>Link of Image or Video</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder="Enter Link"
                    value={fileLink}
                    onChange={(e) => setFileLink(e.target.value)}
                  />
                </Form.Group>
                : <Form.Group controlId="formFile" className='mt-4'>
                  <Form.Label>Upload Image</Form.Label>
                  <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
                </Form.Group>}

              <div className="d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
                <Button variant="outline-danger" className='mt-5 btn-lg' type="button" block onClick={() => navigation("/")}>
                  Home
                </Button>
                <Button variant="outline-primary" className='mt-5 btn-outline-success btn-lg ml-4' type="button" block onClick={handleAddNews}>
                  {loading ? 'Posting...' : 'Add News'}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default AddNewsForm;
