import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import Header from '../Components/Header'
import HiddenNavbar from '../Components/HiddenNavbar'
import Dev from '../assets/breaking_news.jpg';
import SS from '../assets/Screenshot from 2024-01-08 01-34-34.png';
import '../CSS/NewsPost.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicParagraph from '../Components/DynamicParagraph';
import PDFViewer from '../Components/PDFViewer';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import Carousel from '../Components/Carousel';
import profilePic from '../assets/profilepic.jpeg';

export default function NewsPost() {
    const [showHiddenNavbar, setShowHiddenNavbar] = useState(false);
    const [news, setNews] = useState({});
    const { postId } = useParams();
    const newsId = postId;
    const navigation = useNavigate();
    const [render, setRender] = useState(false);
    // console.log(newsId)
    const pageUrl = `https://newsaaptakcg.com/news/${newsId}`;
    const pageTitle = 'Check out this article on NewsAaptakCG';
    const hashtags = ['news', 'article'];

    const handleWhatsAppShare = () => {
        const shareUrl = `whatsapp://send?text=${encodeURIComponent(pageTitle + '\n' + pageUrl)}`;
        window.open(shareUrl);
    };
    const fetchNewsById = () => {
        console.log("I am ankit")
        fetch(`https://d19rkiagm94rwl.cloudfront.net/api/photos/news/${newsId}`).then(response => {
            if (!response.ok)
                throw new Error(`HTTP error! Status: ${response.status}`);
            return response.json();
        })
            .then(data => {
                setNews(data);
                // console.log(data[0])
                // console.log(data.photoUrl)
                console.log(data)
                fetchRelatedArticles(data);
                // console.log(data)
            })
            .catch(error => console.error('Error:', error));
    }

    const [relatedNews, setRelatedNews] = useState([]);

    const fetchRelatedArticles = (data) => {
        const category = data ? data.category : null;

        fetch(`https://d19rkiagm94rwl.cloudfront.net/api/photos/news/get/${category}`)
            .then(response => {
                if (!response.ok)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                return response.json();
            })
            .then(data => {
                const filteredData = data.filter(item => item.postId !== newsId);
                // setRender(!render);
                // console.log(filteredData)
                setRelatedNews(filteredData);
            })
            .catch(error => console.error('Error:', error));
    }


    const [ads, setAds] = useState([]);
    const fetchAds = () => {
        fetch("https://d19rkiagm94rwl.cloudfront.net/api/ads/user/all")
            .then(response => {
                if (!response.ok)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                return response.json();
            })
            .then(data => {
                const adsObject = {};
                data.forEach(({ adsId, adsUrl }) => {
                    adsObject[adsId] = adsUrl;
                });
                // console.log(adsObject)
                setAds(adsObject);
            })
            .catch(error => console.error('Error:', error));
    };

    const navigateTo = (id) => {
        setRender(!render);
        navigation(`/news/${id}`);
    }

    useEffect(() => {
        fetchNewsById();
        fetchAds();
        console.log("I am in")
        window.scrollTo(0, 0);
        // console.log("********************", relatedNews);


        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;

            // Adjust the scroll threshold as needed (20vh in this case)
            setShowHiddenNavbar(scrollPosition > 0.5 * window.innerHeight);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [render]);


    const truncateText = (text, maxWords) => {
        if (text) {
            const words = text.split(' ');

            if (words.length <= maxWords) {
                return text;
            }

            const truncatedText = words.slice(0, maxWords).join(' ');
            return `${truncatedText}...`;
        }
    };

    const toastData = {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const deletePost = (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this post?');
        if (isConfirmed) {
            console.log('Post deleted!');
            const authToken = localStorage.getItem('newsapp_auth_token');
            fetch(`https://d19rkiagm94rwl.cloudfront.net/api/photos/${id}`, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                }
            }).then((response) => {
                // console.log(response)
                toast.success("Post deleted", toastData);
                navigation("/");
            })
        } else {
            // console.log('Deletion canceled.');
            return;
        }
    }

    const mediaUrl = news?.photoUrl || news?.fileLink || Dev;
    const ShareButtons = ({ pageUrl, pageTitle, mediaUrl }) => (
        <div>
          <FacebookShareButton url={pageUrl} quote={`${pageTitle}\n${mediaUrl}`}>
            <i className='fa-brands fa-2x fa-facebook'></i>
          </FacebookShareButton>
          <TwitterShareButton style={{ marginLeft: "2vw" }} url={pageUrl} title={`${pageTitle}\n${mediaUrl}`}>
            <i className='fa-brands fa-2x fa-twitter'></i>
          </TwitterShareButton>
          <WhatsappShareButton url={pageUrl} title={`${pageTitle}\n${mediaUrl}`} separator=":: ">
            <i style={{ marginLeft: "2vw" }} className='fa-brands fa-whatsapp fa-2x'></i>
          </WhatsappShareButton>
        </div>
      );

    // useEffect(()=>{
    //     console.log("I am on")
    // },[])
    return (
        <div>
            <Helmet>
                <meta property="og:title" content={news ? news.heading : 'Default Title'} />
                {/* <meta property="og:description" content={news ? news.summary : 'Default Description'} /> */}
                <meta property="og:image" content={mediaUrl} />
                <meta property="og:url" content={pageUrl} />
            </Helmet>
            <Header />
            <HiddenNavbar showFixed={showHiddenNavbar} />
            <div className="container-fluid main-div mt-5" style={{ width: "80%" }}>
                <div id="news_post_heading" style={{ width: "100%", maxWidth: "100%", overflow: "hidden" }}>
                    <h2 style={{ whiteSpace: "normal", inlineSize: "95%", fontWeight: "bold" }}>{news ? news.heading : "!!Your internet is not working properly!!"}</h2>
                </div>
                <div id="news_post_page_division" className='d-flex mt-5 mb-5'>
                    <div id="news_part" style={{ width: "69%" }}>
                        <div id="news_image" style={{ justifyContent: 'center', alignItems: "center" }} className='d-flex'>
                            {news.fileType === "Image" && (
                                <img
                                    src={news ? news.photoUrl : Dev}
                                    style={{ width: "100%", height: "auto" }}
                                    alt=""
                                />
                            )}

                            {news === undefined && (
                                <img
                                    src={Dev}
                                    style={{ width: "100%", height: "auto" }}
                                    alt=""
                                />
                            )}

                            {news.fileType === "Video" && (
                                <video
                                    src={news ? news.photoUrl : Dev}
                                    style={{ width: "100%", height: "auto" }}
                                    controls
                                >
                                    Your browser does not support the video tag.
                                </video>
                            )}

                            {news.fileType === "Link" && (
                                <iframe
                                    title="Embedded Video"
                                    width="100%"
                                    height="500px"
                                    src={news ? news.fileLink : Dev}
                                    allowFullScreen
                                ></iframe>
                            )}

                            {news.fileType === "Pdf" && (
                                <PDFViewer url={news ? news.photoUrl : null} />

                            )}

                        </div>
                        <div id="news_date" className='mt-5'>
                            <div id='afterPhotoBoxNews' className="d-flex justify-content-between">
                                <div className='d-flex mt-2'>
                                    <i id='newsPostClockIcon' style={{ fontSize: "1vw" }} className="fa-solid fa-clock mt-1"></i>
                                    <h4 id='news_post_date' style={{ fontSize: "1.2vw", textWrap: "wrap" }} className='text-secondary ml-2'>{news ? news.dateTime : "January 24, 2024"}</h4>
                                </div>
                                <div id='shareOptions' style={{ display: "flex" }}>
                                    {/* <h5 className='mr-3'>Share on :</h5> */}
                                    <ShareButtons
                                        pageUrl={pageUrl}
                                        pageTitle={news.heading}
                                        // hashtags={hashtags}
                                        mediaUrl={mediaUrl}
                                    />
                                </div>
                                {localStorage.getItem("newsapp_auth_token") ? <div id='updateDeleteButton' className="d-flex">
                                    <button onClick={() => navigation("/addNews", { state: news.postId })} className='btn btn-sm btn-warning'>Update</button>
                                    <button onClick={() => deletePost(news.postId)} style={{ marginLeft: "10%" }} className='btn btn-sm btn-danger ml-4'>Delete</button>
                                </div> : <div id='profileSectio' className="d-flex" style={{ marginLeft: "10%", marginTop: "-15px" }}>
                                    <div id='profilePicDiv' style={{ width: "3vw", height: "3vw", borderRadius: "50%" }}>
                                        <img src={profilePic} style={{ width: "3vw", height: "3vw", borderRadius: "50%" }} alt="" />
                                    </div>
                                    <h5 id='profileName' style={{ color: "gray", fontWeight: "bold", paddingTop: "15px", paddingLeft: "10px", fontSize: "0.9vw" }}>Suresh Kumar Gajbhiye</h5>
                                </div>}
                            </div>
                            <hr />
                        </div>
                        <div id="main_news_content" style={{ textWrap: "wrap" }}>
                            <DynamicParagraph paragraphWithLink={news && news.c1} />
                        </div>
                        <div id="big_add">
                            <div id='acknowledgemewntCarousel' className='mb-5' style={{ width: "95%", marginLeft: "0%", height: "auto", display: "none" }} alt="">
                                <Carousel />
                            </div>
                            <img className='mb-5' src={ads[2] ? ads[2] : SS} style={{ width: "95%", marginLeft: "0%", height: "70vh", marginTop: "7vh" }} alt="" />
                            <DynamicParagraph paragraphWithLink={news && news.c2} />
                            <img className='mb-5' src={ads[3] ? ads[3] : SS} style={{ width: "95%", marginLeft: "0%", height: "70vh", marginTop: "7vh" }} alt="" />
                            <DynamicParagraph paragraphWithLink={news && news.c3} />
                            <img className='mb-5' src={ads[4] ? ads[4] : SS} style={{ width: "95%", marginLeft: "0%", height: "70vh", marginTop: "7vh" }} alt="" />
                            <DynamicParagraph paragraphWithLink={news && news.c4} />
                            <img className='mb-5' src={ads[5] ? ads[5] : SS} style={{ width: "95%", marginLeft: "0%", height: "70vh", marginTop: "7vh" }} alt="" />
                            <DynamicParagraph paragraphWithLink={news && news.c5} />
                            {/* <img className='mb-5' src={ads[6] ? ads[6] : SS} style={{ width: "95%", marginLeft: "0%", height: "70vh", marginTop: "7vh" }} alt="" /> */}
                            <DynamicParagraph paragraphWithLink={news && news.c6} />
                            {/* <img className='mb-5' src={ads[7] ? ads[7] : SS} style={{ width: "95%", marginLeft: "0%", height: "70vh", marginTop: "7vh" }} alt="" /> */}
                        </div>
                        <div id="related_news" className='mt-5'>
                            <div id="articles_heading">
                                <h4>Related Articles</h4>
                                <hr />
                            </div>
                            {relatedNews && <div id="related_articles_card" className='mt-4 d-flex'>
                                {relatedNews.map((item, index) => (index < 3 ?
                                    <div key={index} className="articles ml-1" onClick={() => navigateTo(item.postId)} style={{ width: "33%", cursor: "pointer" }}>
                                        {
                                            item.fileType == "Image" && <img src={item ? item.photoUrl : Dev} style={{ width: "100%", height: "24vh" }} className='zoomable-image' alt="" />
                                        }
                                        {
                                            item.fileType == "Video" && <video
                                                src={item ? item.photoUrl : Dev}
                                                style={{ width: "100%", height: "24vh" }}
                                                controls
                                            >
                                                Your browser does not support the video tag.
                                            </video>
                                        }
                                        {
                                            item.fileType == "Link" && <iframe
                                                title="Embedded Video"
                                                width="100%"
                                                height="70%"
                                                src={item ? item.fileLink : Dev}
                                                allowFullScreen
                                            ></iframe>
                                        }
                                        {
                                            item.fileType == "Pdf" && <iframe
                                                title="Pdf Viewer"
                                                width="100%"
                                                height="60%"
                                                src={item ? item.photoUrl : null}
                                                allowFullScreen
                                            ></iframe>
                                        }
                                        <h6 className='mt-4' style={{ fontSize: "0.9vw", color: "darkblue" }}>{truncateText(item ? item.heading : "!!Your internet is not working properly!!", 7)}</h6>
                                        <h5 style={{ fontSize: "1vw" }} className='text-secondary mt-3'>{item ? item.dateTime : "January 31, 2024"}</h5>
                                    </div>
                                    : null))}
                                {/* <div className="articles" style={{ width: "33%", marginLeft: "1%" }}>
                                        <img src={Dev} style={{ width: "100%", height: "24vh" }} alt="" />
                                        <h6 className='mt-4' style={{ fontSize: "0.9vw" }}>{truncateText("The 'yourDataArray' array makes the dependencies of useEffect Hook (at line 171) change on every render. To fix this, wrap the initialization of 'yourDataArray'", 7)}</h6>
                                        <h5 style={{ fontSize: "1vw" }} className='text-secondary mt-3'>January 31, 2024</h5>
                                    </div> */}
                                {/* <div className="articles" style={{ width: "33%", marginLeft: "1%" }}>
                                        <img src={Dev} style={{ width: "100%", height: "24vh" }} alt="" />
                                        <h6 className='mt-4' style={{ fontSize: "0.9vw" }}>{truncateText("The 'yourDataArray' array makes the dependencies of useEffect Hook (at line 171) change on every render. To fix this, wrap the initialization of 'yourDataArray'", 7)}</h6>
                                        <h5 style={{ fontSize: "1vw" }} className='text-secondary mt-3'>January 31, 2024</h5>
                                    </div> */}
                            </div>}
                        </div>
                    </div>
                    <div id="add_part" style={{ width: "30%", marginLeft: "1%" }}>
                        <div id="divided_box_23" style={{ marginTop: "10vh", marginLeft: "1%" }}>
                            <div style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "15vh" }}>
                                <Carousel />
                            </div>
                            <img src={ads[6] ? ads[6] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "15vh" }} alt="" />
                            <img src={ads[7] ? ads[7] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "17vh" }} alt="" />
                            {/* <img src={ads[2] ? ads[2] : SS} style={{ width: "90%", marginLeft: "10%", height: "40vh", marginTop: "17vh" }} alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
